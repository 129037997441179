import React, { useState, useMemo } from 'react'
import { getDefGenerators } from '../ActivationViewUtil'
import { ActivationCanvas } from '../ActivationComponents/ActivationRenderer'
import { FeatureVisRow } from '../FeatureVisComponents/FeatureVisRow'
import { FormControlLabel, Checkbox, Tooltip } from '@material-ui/core'
import { Visibility } from '@material-ui/icons'
import { ArrowOverlay } from './ArrowOverlay'
import { getModelDirName } from '../../Model/ModelMetaInfo'
import {
    ttCentralModelCheckBoxes, ttFeatureMapView,
    ttFeatureVisView
} from '../../strings'


export function CombinedActivationView(props) {
    const { modelManager, activationParams,
        neuronWidth, totalWidth, onCycleNeuronsUp, onCycleNeuronsDown, setNeedsUpdate,
        naiveParam, showActivations, modelIterations, modelTrainTypes,
        dragging, showHelp
    } = props;

    const [visibleModelRows, setVisibleModelRows] = useState({ 0: true, 1: true, 2: false });

    const loadState = modelManager.useStore(state => state.loaded);
    const setLoadState = modelManager.useStore(state => state.setLoaded);

    const visibleModelInds = [];
    Object.entries(visibleModelRows).forEach(([ind, visibility]) => {
        visibleModelInds.push(parseInt(ind));
    });

    const modelDirs = useMemo(() => {
        const model1Name = getModelDirName(modelTrainTypes[0], modelIterations[0]);
        const model2Name = getModelDirName(modelTrainTypes[1], modelIterations[1]);
        return [model1Name, model2Name];
    }, [modelTrainTypes, modelIterations]);
    const initMixedModel = () => {
        console.log("initing mixed model")
        modelManager.getMixedModel().init(() => {
            console.log("inited mixed model")
            setLoadState(2, true);
            setNeedsUpdate(true);
        });
    }
    modelManager.modelDirs = modelDirs;
    const loadingModels = [];
    modelDirs.forEach((modelDir, i) => {
        if (!dragging && (!modelManager.models[i] || modelDir !== modelManager.models[i].modelName ||
            (!modelManager.models[i].isLoaded() && !modelManager.models[i].isLoading))) {
            if (modelManager.models[i]) {
                modelManager.models[i].dispose()
            }
            loadingModels.push(i);
            modelManager.setModel(i, () => {
                setLoadState(i, true);
                modelManager.getMixedModel().updateWeights();
                setNeedsUpdate(true);
                initMixedModel();
            }, true);
        }
    });
    loadingModels.forEach(i => {
        setLoadState(i, false);
        console.log("setLoadstate", i, false)
    })

    const models = visibleModelInds.map(index => {
        return modelManager.getModel(index);
    });

    const modelClassNames = ["model1Color", "model2Color", "mixedModelColor"];

    return (<div className="column">
        <Tooltip title={showHelp ? ttCentralModelCheckBoxes : ""}>
            <div className="row">
                <div className="model1Color padding" style={{ flex: "1 1 auto", borderRadius: "10px" }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={visibleModelRows[0]}
                                onChange={() => {
                                    const newVisibleRows = { ...visibleModelRows };
                                    newVisibleRows[0] = !visibleModelRows[0];
                                    setVisibleModelRows(newVisibleRows);
                                    setNeedsUpdate(true);
                                }}
                                name="checkedB"
                                color="primary"
                            />
                        }
                        label="Model 1"
                    /></div>
                <div className="model2Color padding" style={{ flex: "1 1 auto", borderRadius: "10px" }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={visibleModelRows[1]}
                                onChange={() => {
                                    const newVisibleRows = { ...visibleModelRows };
                                    newVisibleRows[1] = !visibleModelRows[1];
                                    setVisibleModelRows(newVisibleRows);
                                    setNeedsUpdate(true);
                                }}
                                name="checkedB"
                                color="primary"
                            />
                        }
                        label="Model 2"
                    /></div>
                <div className="mixedModelColor padding" style={{ flex: "1 1 auto", borderRadius: "10px" }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={visibleModelRows[2]}
                                onChange={() => {
                                    const newVisibleRows = { ...visibleModelRows };
                                    newVisibleRows[2] = !visibleModelRows[2];
                                    setVisibleModelRows(newVisibleRows);
                                    setNeedsUpdate(true);
                                }}
                                color="primary"
                            />
                        }
                        label="Edited Model"
                    /></div>
            </div>
        </Tooltip>
        <div style={{ position: "relative" }}>
            <div>
                {models.map((m, i) => {
                    return (
                        <div style={{ padding: 10, borderRadius: "10px", overflow: "hidden" }} className={modelClassNames[i]}>
                            <Tooltip title={showHelp ? ttFeatureMapView : ""}>
                                <div style={{ position: "relative" }}>
                                    <div className="row">
                                        <ActivationCanvas index={i} model={m} visible={visibleModelRows[i] && showActivations}
                                            width={totalWidth} height={neuronWidth}
                                            activationParams={activationParams} />
                                    </div>
                                    {visibleModelRows[i] && <div style={{
                                        top: 0, left: 0,
                                        position: "absolute", zIndex: 10,
                                        background: "white", opacity: 0.5,
                                        //top:3, left:3,
                                        borderRadius: "15px 5px 5px 5px",
                                        padding: 3, fontFamily: "arial", fontSize: "12px",
                                    }}>{["Model 1", "Model 2", "Edited Model"][visibleModelInds[i]]}</div>}
                                </div>
                            </Tooltip>
                            {visibleModelRows[i] &&
                                <Tooltip title={showHelp ? ttFeatureVisView : ""}>
                                    <div className='row'>
                                        <FeatureVisRow
                                            model={m}
                                            params={activationParams[i]}
                                            neuronWidth={neuronWidth}
                                            usePriorVis={!naiveParam}
                                        />
                                    </div>
                                </Tooltip>}
                        </div>);
                })}
            </div>
            <ArrowOverlay
                onRightClick={onCycleNeuronsUp}
                onLeftClick={onCycleNeuronsDown}
            />
        </div>
    </div>);
}