import React, { useRef, useEffect, useMemo } from 'react'
import { extend, useThree, useFrame, useLoader } from 'react-three-fiber'
import {TextureLoader, Vector3, ShaderMaterial, Texture} from 'three'
import {StaticBillboardShader} from './Shaders'
import {loadTexture} from './ObjectUtil'

export const backgrounds = [
    {
        name: "Grass",
        url: '/assets/grass.jpg'
    },
    {
        name: "Street",
        url: '/assets/street.jpg'
    }
];
// textures are public domain images from
// https://www.flickr.com/photos/trianglereva/14733514402/in/photolist-orX7wo-Z94iPi-CScozM-Er1XtD-288U1wq-XV2wgq-28mPQnQ-24pbfuk-2bvoUSc-PXndPY-2cYtQ1w-JBvJaq-S8PwVj-AanKpm-KeaAry-2akvjrr-2aBCu3j-PSZqVs-23f2MmB-28EnbDW-24v8vFD-ScrZQS-KfsnzX-2et83Us-29tdigv-27thd4d-2bbkovv-26mPosi-HiGjGT-28RguXR-MExL9i-2a4K4zB-YZLg5x-2cLoXg6-E7HAKr-22a9gM1-HAkM3K-26bavt2-EpLxhY-27Ppjsr-AgoECo-HxevER-FRGePM-22kfCRq-Em6JUb-bjC7kx-eCRyVo-21HoRFy-23RwbaM-2bdu7Ay
// https://www.flickr.com/photos/matsuyuki/5203595943/in/photolist-8VPMMr-8VSS1o-2BKsCX-JxpSPM-7syqxg-pptnwN-6UVdSG-5ViKmY-qdtetT-bCkvip-GzwryQ-4jmdC-qbEsUV-qbMkjb-3CYrJ-7s6ACa-27gUmoQ-5vRYwD-p94gkF-3CYsR-dbbscN-25fZqwg-9mqQez-hvz8WM-pgELWa-HZzXNc-24jHngo-21eXgYn-pUhxm9-26ZfkPf-zsAtde-pUgB4E-fqtwAP-8oC6Fe-bAe2nk-9GaQQQ-vPBPv9-pPF8Ej-G3XHvH-butkce-9UWjP1-t4QpmA-dE4LNS-qg1uj-4NEXeT-RzcM5n-fziTJ9-6HUMAD-H6qCoy-puyJ3o
export function TextureBillboard(props) {
    const {uniforms, textureUrl} = props;
    const plane = useRef()
    const ref = useRef()
    const textureRef = useRef();
    let rot = 0

    useMemo(() => {
        if (typeof textureUrl !== 'string') {
            const texture = new Texture();
            loadTexture(texture, textureUrl);
            textureRef.current = texture;
        } else {
            new TextureLoader().load(textureUrl, (texture) => {
                textureRef.current = texture;
            });
        }
    }, [textureUrl]);



    const shaderMaterial = useMemo(() => new ShaderMaterial({
        uniforms: {...StaticBillboardShader.uniforms},
        vertexShader: StaticBillboardShader.vertexShader,
        fragmentShader: StaticBillboardShader.fragmentShader
    }), []);

    useFrame(() => {
        if( textureRef.current) {
            shaderMaterial.uniforms.color.value = textureRef.current;
            textureRef.current.needsUpdate=true;
        }
        shaderMaterial.uniforms.zPos.value = 1.0;
        if('lod' in uniforms) {
            shaderMaterial.uniforms.lod.value = uniforms.lod;
        }
        if('saturation' in uniforms) {
            shaderMaterial.uniforms.saturation.value = uniforms.saturation;
        }
        if(ref.current) {
            ref.current.frustumCulled = false;
            ref.current.position.set(0,0,-5);
            ref.current.updateMatrix();
            ref.current.material = shaderMaterial;
        }
    });

    return <mesh
    ref={ref}>
    <planeBufferGeometry ref={plane} attach="geometry" args={[2, 2, 1]} />
    </mesh>
}
