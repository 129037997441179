// copied from: https://github.com/distillpub/post--circuits-early-overview/blob/master/layer_data/conv2d1.json
const interestingNeurons = {
    "conv2d0": [
        {
            "name": "Gabor Filters",
            "units": [
                9,
                53,
                54,
                15,
                22,
                39,
                30,
                1,
                3,
                49,
                14,
                17,
                62,
                20,
                27,
                0,
                10,
                28,
                21,
                63,
                45,
                18,
                6,
                57,
                41,
                43,
                46,
                8
            ],
            "comment": "Gabor filters are a simple edge detector, highly sensitive to the alignment of the edge. They're almost universally found in the fist layer of vision models. Note that Gabor filters almost always come in pairs of negative reciprocals."
        },
        {
            "name": "Color Contrast",
            "units": [
                59,
                23,
                5,
                7,
                48,
                29,
                12,
                24,
                55,
                13,
                32,
                50,
                36,
                37,
                16,
                11,
                33,
                34,
                47,
                38,
                58,
                2,
                60,
                61,
                4,
                51,
                25
            ],
            "comment": "These units detect a color one side of their receptive field, and the opposite color on the other side. Compare to later color contrast (<a href='#group_conv2d1_color_contrast'><code>conv2d1</code></a>, <a href='#group_conv2d2_color_contrast'><code>conv2d2</code></a>, <a href='#group_mixed3a_color_contrast'><code>mixed3a</code></a>, <a href='#group_mixed3b_color_contrast_gradient'><code>mixed3b</code></a>)."
        },
        {
            "name": "Other Units",
            "units": [
                19,
                26,
                31,
                35,
                40,
                42,
                44,
                52,
                56
            ],
            "comment": "Units that don't fit in another category."
        }
    ],
    "conv2d1": [
        {
            "name": "Color",
            "units": [
                2,
                48,
                36,
                46
            ],
            "comment": "Two of these units seem to track brightness (bright vs dark), while the other two units seem to mostly track hue, dividing the space of hues between them. One responds to red/orange/yellow, while the other responds to purple/blue/turqoise. Unfortunately, their circuits seem to heavily rely on the existence of a Local Response Normalization layer after <code>conv2d0</code>, which makes it hard to reason about."
        },
        {
            "name": "Multicolor",
            "units": [
                3,
                33,
                35,
                40,
                17,
                16,
                57,
                26,
                31
            ],
            "comment": "These units respond to mixtures of colors without an obvious strong spatial structure preference."
        },
        {
            "name": "Color Contrast",
            "units": [
                5,
                9,
                10,
                12,
                20,
                21,
                42,
                45,
                50,
                53
            ],
            "comment": "These units detect a color on one side of the receptive field, and a different color on the opposite side. Composed of lower-level color contrast detectors, they often respond to color transitions in a range of translation and orientation variations. Compare to earlier <a href='#group_conv2d0_color_contrast'>color contrast (<code>conv2d0</code>)</a> and later color contrast (<a href='#group_conv2d2_color_contrast'><code>conv2d2</code></a>, <a href='#group_mixed3a_color_contrast'><code>mixed3a</code></a>, <a href='#group_mixed3b_color_contrast_gradient'><code>mixed3b</code></a>)."
        },
        {
            "name": "Low Frequency",
            "units": [
                1,
                13,
                27,
                47,
                56,
                60,
                23,
                49,
                0,
                43,
                28,
                29,
                34,
                8,
                37,
                19,
                15,
            ],
            "comment": "These units seem to respond to lower-frequency edge patterns, but we haven't studied them very carefully."
        },
        {
            "name": "Complex Gabor",
            "units": [
                51,
                58,
                30,
                25,
                52,
                54,
                22,
                61,
                55
            ],
            "comment": "Like Gabor Filters, but fairly invariant to the exact position, formed by adding together multiple Gabor detectors in the same orientation but different phases. We call these 'Complex' after <a href='https://en.wikipedia.org/wiki/Complex_cell'>complex cells</a> in neuroscience."
        },
        {
            "name": "hatch",
            "units": [
                14
            ],
            "comment": "This unit detects Gabor patterns in two orthogonal directions, selecting for a \"hatch\" pattern."
        },
        {
            "name": "Gabor Like",
            "units": [
                4,
                6,
                32,
                38,
                41,
                63,
                7,
                11,
                18,
                24,
                39
            ],
            "comment": "These units respond to edges stimuli, but seem to respond to a wider range of orientations, and also respond to color contrasts that align with the edge. We haven't studied them very carefully."
        },
        {
            "name": "Other Units",
            "units": [
                44,
                62,
                59
            ],
            "comment": "Units that don't fit in another category.",
            "priority": -1
        }
    ],
    "conv2d2": [
        {
            "name": "Tiny Curves",
            "units": [
                182,
                117,
                171,
                111,
                63,
                130,
                146,
                180,
                39,
                140,
                32,
                80
            ],
            "comment": "Very small curve (and one circle) detectors. Many of these units respond to a range of curvatures all the way from a flat line to a curve. Compare to later <a href='#group_mixed3a_curves'>curves (<code>mixed3a</code>)</a> and <a href='#group_mixed3b_curves'>curves (<code>mixed3b</code>)</a>. See also <a href='#mixed3a_discussion_small_circle'>circuit example and discussion</a> of use in forming <a href='#group_mixed3a_eyes_small_circles'>small circles/eyes (<code>mixed3a</code>)</a>."
        },
        {
            "name": "Early Brightness Gradient",
            "units": [
                94,
                142,
                99,
                104,
                163,
                188,
                98,
                165,
                83,
                79,
                128,
                5
            ],
            "comment": "These units detect oriented gradients in brightness. They support a variety of similar units in the next layer. Compare to later <a href='#group_mixed3a_brightness_gradient'>brightness gradients (<code>mixed3a</code>)</a> and <a href='#group_mixed3b_brightness_gradients'>brightness gradients (<code>mixed3b</code>)</a>."
        },
        {
            "name": "Line Divergence",
            "units": [
                137,
                53
            ],
            "comment": "These units detect lines diverging from a point."
        },
        {
            "name": "Color Contrast",
            "units": [
                10,
                36,
                172,
                56,
                131,
                176,
                35,
                41,
                45,
                126,
                77,
                120,
                101,
                0,
                43,
                62,
                106,
                169,
                127,
                6,
                68,
                60,
                134,
                51,
                74,
                85,
                115,
                24,
                14,
                16,
                151,
                168,
                19,
                7,
                88,
                177,
                183,
                76,
                70,
                122
            ],
            "comment": "These units detect a color on one side of the receptive field, and a different color on the opposite side. Composed of lower-level color contrast detectors, they often respond to color transitions in a range of translation and orientation variations. Compare to earlier color contrast (<a href='#group_conv2d0_color_contrast'><code>conv2d0</code></a>, <a href='#group_conv2d1_color_contrast'><code>conv2d1</code></a>) and later color contrast (<a href='#group_mixed3a_color_contrast'><code>mixed3a</code></a>, <a href='#group_mixed3b_color_contrast_gradient'><code>mixed3b</code></a>)."
        },
        {
            "name": "Gabor Textures",
            "units": [
                89,
                91,
                110,
                114,
                123,
                135,
                139,
                143,
                144,
                167,
                173,
                118
            ],
            "comment": "Like complex Gabor units from the previous layer, but larger. They're probably starting to be better described as a texture."
        },
        {
            "name": "Hatch Textures",
            "units": [
                164,
                184,
                28,
                121,
                159,
                102
            ],
            "comment": "These units detect Gabor patterns in two orthogonal directions, selecting for a \"hatch\" pattern."
        },
        {
            "name": "Texture Contrast",
            "units": [
                1,
                37,
                71,
                73,
                105,
                147,
                178,
                181
            ],
            "comment": "These units look for different textures on opposite sides of their receptive field. One side is typically a Gabor pattern."
        },
        {
            "name": "Textures",
            "units": [
                21,
                52,
                59,
                119,
                148,
                161,
                162,
                186,
                189,
                191,
                72,
                46,
                67,
                40,
                38
            ],
            "comment": "A broad category of units detecting repeating local structure."
        },
        {
            "name": "Line",
            "units": [
                107,
                31,
                9,
                112,
                133,
                103,
                97,
                125,
                20,
                33,
                113,
                185,
                150,
                166,
                157,
                57,
                145,
                48,
                55,
                15,
                152,
                11,
                141,
                174,
                44,
                170,
                27,
                100,
                30,
                82,
                65,
                3,
                108
            ],
            "comment": "These units are beginning to look for a single primary line. Some look for different colors on each side. Many exhibit \"combing\" (small perpendicular lines along the main one), a very common but not presently understood phenomenon in line-like features across vision models. Compare to <a href='#group_conv2d2_shifted_line'>shifted lines</a> and later <a href='#group_mixed3a_lines'>lines (<code>mixed3a</code>)</a>."
        },
        {
            "name": "Corners",
            "units": [
                47,
                87,
                95,
                26
            ],
            "comment": "These units detect two Gabor patterns which meet at apprixmately 90 degrees, causing them to respond to corners."
        },
        {
            "name": "Shifted Line",
            "units": [
                116,
                61,
                8,
                22,
                69,
                96,
                78,
                18,
                17,
                132,
                190,
                64,
                81,
                154,
                179,
                136
            ],
            "comment": "These units look for edges \"shifted\" to the side of the receptive field instead of the middle. This may be linked to the many 1x1 convs in the next layer. Compare to <a href='#group_conv2d2_line'>lines</a> (non-shifted) and later <a href='#group_mixed3a_lines'>lines (<code>mixed3a</code>)</a>."
        },
        {
            "name": "Color/Multicolor",
            "units": [
                58,
                75,
                92,
                42,
                12
            ],
            "comment": "Several units look for mixtures of colors but seem indifferent to their organization."
        },
        {
            "name": "Color Center-Surround",
            "units": [
                155,
                34,
                156,
                13,
                49,
                138,
                23,
                160,
                86,
                124,
                25,
                4,
                29
            ],
            "comment": "These units look for one color in the middle and another (typically opposite) on the boundary. Genereally more sensitive to the center than boundary. Compare to later <a href='#group_mixed3a_color_center_surround'> Color Center-Surround (<code>mixed3a</code>)</a> and <a href='#group_mixed3b_color_center_surround'>Color Center-Surround  (<code>mixed3b</code>)</a>."
        },
        {
            "name": "Other Units",
            "units": [
                2,
                50,
                54,
                66,
                84,
                90,
                93,
                109,
                129,
                149,
                153,
                158,
                175,
                187
            ],
            "comment": "Catch-all category for all other units.",
            "priority": -1
        }
    ],
    "mixed3a": [
        {
            "name": "Fur Precursors",
            "units": [
                46,
                47,
                26,
                63,
                80,
                23,
                16
            ],
            "comment": "These units are not yet highly selective for fur (they also fire for other high-frequency patterns), but their primary use in the next layer is supporting <a href='#group_mixed3b_generic_oriented_fur'>fur detection</a>. At the 224x224 image resolution, individual fur hairs are generally not detectable, but tufts of fur are. These units use Gabor textures to detect those tufts in different orientations. The also detect lower frequency edges or changes in lighting perpendicular to the tufts."
        },
        {
            "name": "Eyes / Small Circles",
            "units": [
                174,
                168,
                79,
                125,
                175
            ],
            "comment": "We think of eyes as high-level features, but small eye detectors actually form very early. Compare to later <a href='#group_mixed3b_eyes'>eye detectors (<code>mixed3b</code>)</a>. See also <a href='#mixed3a_discussion_small_circle'>circuit example and discussion</a>."
        },
        {
            "name": "High-Low Frequency",
            "units": [
                110,
                180,
                153,
                106,
                112,
                186,
                132,
                136,
                117,
                113,
                108,
                70,
                86,
                88,
                160
            ],
            "comment": "These units look for transitions from high-frequency texture to low-frequency. They are primarily used by <a href='#group_mixed3b_boundary'>boundary detectors (<code>mixed3b</code>)</a> as an additional cue for a boundary between objects. (Larger scale high-low frequency detectors can be found in <code>mixed4a</code> (245, 93, 392, 301), but are not discussed in this article.) <br><br> A detailed article on these is forthcoming."
        },
        {
            "name": "Brightness Gradient",
            "units": [
                216,
                127,
                22,
                182,
                162,
                25,
                249,
                15,
                28,
                59,
                29,
                196,
                206,
                18,
                247
            ],
            "comment": "These units detect brightness gradients. Among other things they will help detect specularity (shininess), curved surfaces, and the boundary of objects. Compare to earlier <a href='#group_conv2d2_early_brightness_gradient'>brightness gradients (<code>conv2d2</code>)</a> and later <a href='#group_mixed3b_brightness_gradients'>brightness gradients (<code>mixed3b</code>)</a>."
        },
        {
            "name": "BW vs Color",
            "units": [
                214,
                208,
                201,
                223,
                210,
                197,
                222,
                204,
                220,
            ],
            "comment": "These \"black and white\" detectors respond to absences of color. Prior to this, color detectors contrast to the opposite hue, but from this point on we'll see many compare to the absence of color. See also <a href='#mixed3a_discussion_BW'>BW circuit example and discussion</a>."
        },
        {
            "name": "Color Contrast",
            "units": [
                195,
                84,
                85,
                123,
                203,
                217,
                199,
                211,
                205,
                212,
                202,
                200,
                138,
                32
            ],
            "comment": "These units look for one color on one side of their receptive field, and another (usually opposite) color on the opposing side. They typically don't care about the exact position or orientation of the transition. Compare to earlier color contrast (<a href='#group_conv2d0_color_contrast'><code>conv2d0</code></a>, <a href='#group_conv2d1_color_contrast'><code>conv2d1</code></a>, <a href='#group_conv2d2_color_contrast'><code>conv2d2</code></a>) and later color contrast (<a href='#group_mixed3b_color_contrast_gradient'><code>mixed3b</code></a>)."
        },
        {
            "name": "Color Center-Surround",
            "units": [
                119,
                34,
                167,
                76,
                19,
                30,
                131,
                251,
                226,
                13,
                7,
                50,
                1,
                4,
                41,
                192,
                36,
                40,
                103,
                213,
                10,
                35,
                221,
                193,
                158,
                73,
                74,
                177,
                97,
                141
            ],
            "comment": "These units look for one color in the center, and another (usually opposite) color surrounding it. They are typically much more sensitive to the center color than the surrounding one. In visual neuroscience, center-surround units are classically an extremely low-level feature, but we see them in the later parts of early vision. Compare to earlier <a href='#group_conv2d2_color_center_surround'> Color Center-Surround (<code>conv2d2</code>)</a> and later <a href='#group_mixed3b_color_center_surround'>Color Center-Surround  (<code>mixed3b</code>)</a>."
        },
        {
            "name": "Texture",
            "units": [
                246,
                242,
                253,
                232,
                233,
                209,
                139,
                65,
                44,
                51,
                194,
                207,
                111,
                218,
                224,
                225,
                215,
                198,
                62,
                21,
                254,
                255,
                61,
                2,
                3,
                8,
                12,
                53,
                56,
                102,
                148,
                244,
                250,
                11,
                238,
                248,
                9,
                219,
                234,
                252,
                236,
                5,
                183,
                241,
                229,
                93,
                243,
                99,
                45,
                33,
                135,
                231,
                60,
                235,
                48,
                55,
                42,
                151,
                54,
                72,
                6,
                239,
                66,
                129,
                245
            ],
            "comment": "This is a broad, not very well defined category for units that seem to look for simple local structures over a wide receptive field, including mixtures of colors. Many live in a branch consisting of a maxpool followed by a 1x1 conv, which structurally encourages this.<d-footnote>Maxpool branches (ie. maxpool 5x5 stride 1 -> conv 1x1) have large receptive fields, but can't control where in in their receptive field each feature they detect is, nor the relative position of these features. In early vision, this unstructured of feature detection makes them a good fit for textures. </d-footnote>"
        },
        {
            "name": "Angles",
            "units": [
                188,
                94,
                164,
                107,
                77,
                157,
                149,
                100
            ],
            "comment": "Units that detect multiple lines, forming angles, triangles and squares. They generally respond to any of the individual lines, and more strongly to them together."
        },
        {
            "name": "Repeating patterns",
            "units": [
                237,
                31,
                17,
                20,
                39,
                126,
                124,
                156,
                98,
                105,
                230,
                228
            ],
            "comment": "This is broad, catch-all category for units that seem to look for repeating local patterns that seem more complex than textures."
        },
        {
            "name": "Complex Center-Surround",
            "units": [
                178,
                181,
                161,
                166,
                172,
                68,
                130,
                49,
                52,
                114,
                115,
                120,
                144,
                37
            ],
            "comment": "This is a broad, not very well defined category for center-surround units that detect a pattern or complex texture in their center."
        },
        {
            "name": "Curves",
            "units": [
                81,
                104,
                92,
                145,
                95,
                163,
                171,
                71,
                147,
                189,
                137
            ],
            "comment": "These curve detectors detect significantly larger radii curves than their predecessors. They will be refined into more specific, larger curve detectors in the next layer. Compare to earlier <a href='#group_conv2d2_tiny_curves'>curves (<code>conv2d2</code>)</a> and later <a href='#group_mixed3b_curves'>curves (<code>mixed3b</code>)</a>. <br><br>See the <a href='https://distill.pub/2020/circuits/curve-detectors/'>full paper on curve detectors</a>."
        },
        {
            "name": "Crosses / Diverging Lines",
            "units": [
                91,
                185,
                64,
                118
            ],
            "comment": "These units seem to respond to lines crossing or to lines diverging from a central point."
        },
        {
            "name": "Line Ends",
            "units": [
                133,
                96
            ],
            "comment": "These units detect a line ending or sharply turning. Often used in boundary detection and more complex shape detectors."
        },
        {
            "name": "Line Misc.",
            "units": [
                191,
                121,
                116,
                14,
                24,
                0,
                159,
                152,
                165,
                83,
                173,
                87,
                90,
                82
            ],
            "comment": "Broad, low confidence organizational category."
        },
        {
            "name": "Thick Lines",
            "units": [
                140,
                78,
                89
            ],
            "comment": "Low confidence organizational category."
        },
        {
            "name": "Lines",
            "units": [
                227,
                75,
                146,
                69,
                169,
                57,
                154,
                187,
                27,
                134,
                150,
                240,
                101,
                176
            ],
            "comment": "Units used to detect extended lines, often further excited by different colors on each side. A few are highly combed line detectors that aren't obviously such at first glance. The decision to include a unit was often decided by whether it seems to be used by downstream client units as a line detector."
        },
        {
            "name": "Other Units",
            "units": [
                38,
                43,
                58,
                67,
                190,
                109,
                122,
                128,
                142,
                143,
                155,
                170,
                179,
                184
            ],
            "comment": "Catch-all category for all other units.",
            "priority": -1
        }
    ],
    "mixed3b": [
        {
            "name": "Other Fur",
            "units": [
                472,
                476,
                477,
                453,
                454,
                427,
                449,
                467,
                64,
                129
            ],
            "comment": "Units which seem to detect fur but, unlike the oriented fur detectors, don't seem to detect it parting in a particular way. Many of these seem to prefer a particular fur pattern."
        },
        {
            "name": "Texture",
            "units": [
                3,
                40,
                32,
                54,
                74,
                309,
                267,
                438,
                416,
                440,
                460,
                276,
                458,
                132,
                133,
                106,
                120,
                123,
                426,
                434,
                429,
                445,
                452,
                456,
                459,
                464,
                465,
                421,
                437,
                418,
                425,
                221,
                195,
                204,
                39,
                76,
                77,
                468,
                471,
                227,
                415,
                126,
                128,
                172
            ],
            "comment": "This is a broad, not very well defined category for units that seem to look for simple local structures over a wide receptive field, including mixtures of colors. "
        },
        {
            "name": "Color Center-Surround",
            "units": [
                285,
                451,
                208,
                122,
                93,
                75,
                46,
                294,
                44,
                247,
                91,
                14,
                10,
                271,
                60,
                80,
                84,
                70,
                202,
                422,
                48,
                436,
                65,
                300,
                105,
                34,
                121,
                424,
                457,
                186,
                23,
                479,
                89,
                283,
                22,
                124,
                6,
                9,
                50,
                5,
                71,
                59,
                182,
                87,
                308,
                428,
                109,
                141,
                12,
                474,
                112,
                192,
                2,
                177,
                249,
                281,
                284,
                30,
                27,
                255,
                53,
                432,
                475,
                79,
                67,
                25,
                351,
                420,
                152,
                26,
                193,
                448,
                153,
                164,
                113,
                216,
                259,
            ],
            "comment": "These units look for one color in the center, and another color surrounding it. These units likely have many subtleties about the range of hues, texture preferences, and interactions that similar neurons in earlier layers may not have. Note how many units detect the absence (or generic presence) of color, building off of the <a href='#group_mixed3a_bw_vs_color'>black and white detectors</a> in <code>mixed3a</code>. Compare to earlier <a href='#group_conv2d2_color_center_surround'> Color Center-Surround (<code>conv2d2</code>)</a> and <a href='#group_mixed3a_color_center_surround'> (Color Center-Surround <code>mixed3a</code>)</a>."
        },
        {
            "name": "Complex Center-Surround",
            "units": [
                299,
                139,
                7,
                170,
                16,
                28,
                291,
                439,
                443,
                69,
                11,
                13,
                56,
                116,
                117,
                72,
                36,
                35,
                41,
                51,
                55,
                88,
                101,
                110,
                114,
                158,
                161,
                169,
                176,
                215,
                228,
                230,
                232,
                233,
                234,
                238,
                242,
                244,
                245,
                252,
                256,
                275,
                280,
                290,
                296,
                297,
                302,
                310,
                410,
                442,
                17,
                8,
                15,
                18,
                20,
                24,
                31,
                37,
                42,
                61,
                73,
                92,
                315,
                103,
                104,
                118,
                119,
                131,
                274,
                278,
                289,
                29,
                147
            ],
            "comment": "This is a broad, not very well defined category for center-surround units that detect a pattern or complex texture in their center."
        },
        {
            "name": "Lines",
            "units": [
                377,
                326,
                95,
                38,
                307,
                1,
                19,
                209,
                210
            ],
            "comment": "Units which seem, to a significant extent, to detect a line. Many seem to have additional, more complex behavior."
        },
        {
            "name": "Brightness Gradients",
            "units": [
                0,
                317,
                136,
                455,
                417,
                469,
            ],
            "comment": "These units detect brightness gradients. This is their third iteration; compare to earlier <a href='#group_conv2d2_early_brightness_gradient'>brightness gradients (<code>conv2d2</code>)</a> and <a href='#group_mixed3a_brightness_gradient'>brightness gradients (<code>mixed3a</code>)</a>.",
            "priority": 1
        },
        {
            "name": "Generic, Oriented Fur",
            "units": [
                57,
                387,
                404,
                333,
                375,
                381,
                335,
                378,
                62,
                52
            ],
            "comment": "We don't typically think of fur as an oriented feature, but it is. These units detect fur parting in various ways, much like how hair on your head parts.",
            "priority": 1
        },
        {
            "name": "Eyes",
            "units": [
                370,
                352,
                363,
                322,
                83,
                199
            ],
            "comment": "Again, we continue to see eye detectors quite early in vision. Note that several of these detect larger eyes than the earlier <a href='#group_mixed3a_eyes_small_circles'>eye detectors (mixed3a)</a>. In the next layer, we see much larger scale eye detectors again.",
            "priority": 1
        },
        {
            "name": "Circle Cluster",
            "units": [
                446,
                462,
                82
            ],
            "comment": "Units detecting circles and curves without necesarily requiring spatial coherrence.",
            "priority": 1
        },
        {
            "name": "Boundary",
            "units": [
                220,
                402,
                364,
                293,
                356,
                151,
                203,
                394,
                376,
                400,
                328,
                219,
                320,
                313,
                329,
                321,
                251,
                298,
                257,
                143,
                366,
                345,
                405,
                414,
                301,
                368,
                398,
                383,
                396,
                261,
                184,
                144,
                360,
                183,
                239,
                386
            ],
            "comment": "These units use multiple cues to detect the boundaries of objects. They vary in orientation, detecting convex/concave/straight boundaries, and detecting artificial vs fur foregrounds. Cues they rely on include line detectors, high-low frequency detectors, and color contrast.",
            "priority": 1
        },
        {
            "name": "Curves",
            "units": [
                379,
                406,
                385,
                343,
                342,
                388,
                340,
                330,
                349,
                324
            ],
            "comment": "The third iteration of curve detectors. They detect larger radii curves than their predecessors, and are the first to not slightly fire for curves rotated 180 degrees. Compare to the earlier <a href='#group_conv2d2_tiny_curves'>curves (conv2d2)</a> and <a href='#group_mixed3a_curves'>curves (mixed3a)</a>. <br><br>See the <a href='https://distill.pub/2020/circuits/curve-detectors/'>full paper on curve detectors</a>.",
            "priority": 1
        },
        {
            "name": "Double Curves",
            "units": [
                359,
                337,
                380
            ],
            "comment": "Weights appear to be two curve detectors added together. Likely best thought of as a polysemantic neuron.",
            "priority": 1
        },
        {
            "name": "Curves misc.",
            "units": [
                348,
                407,
                365,
                367
            ],
            "comment": "Low confidence organizational category."
        },
        {
            "name": "Shallow Curves",
            "units": [
                403,
                353,
                355,
                336
            ],
            "comment": "Detectors for curves with wider radii than <a href='#group_mixed3b_curves'>regular curve detectors</a>.",
            "priority": 1
        },
        {
            "name": "Curve Shapes",
            "units": [
                325,
                338,
                327,
                347
            ],
            "comment": "Simple shapes created by composing curves, such as spirals and S-curves.",
            "priority": 1
        },
        {
            "name": "Divots",
            "units": [
                395,
                159,
                237,
                409,
                357,
                190,
                212,
                211,
                198,
                218
            ],
            "comment": "Curve-like detectors for sharp corners or bumps.",
            "priority": 1
        },
        {
            "name": "Cross / Corner Divergence",
            "units": [
                108,
                47,
                339,
                344,
                374,
                99,
                369,
                236,
                408
            ],
            "comment": "Units detecting lines crossing or diverging from a center point. Some are early predecessors for 3D corner detection."
        },
        {
            "name": "Square / Grid",
            "units": [
                392,
                361,
                401,
                68,
                341,
                382,
                397,
                66,
                125
            ],
            "comment": "Units detecting grid patterns.",
            "priority": 1
        },
        {
            "name": "Evolute",
            "units": [
                373
            ],
            "comment": "Detects curves facing away from the middle. Opposite of <a href='#group_mixed3b_circles_loops'>circles</a>. Term repurposed from <a href='https://en.wikipedia.org/wiki/Evolute'>mathematical evolutes</a> which can sometimes be visually similar.",
            "priority": 1
        },
        {
            "name": "Circles / Loops",
            "units": [
                389,
                384,
                346,
                323
            ],
            "comment": "Piece together curves in a circle or partial circle. Opposite of <a href='#group_mixed3b_evolute'>evolute</a>.",
            "priority": 1
        },
        {
            "name": "Line Grad",
            "units": [
                21,
                63,
                102,
                423,
                175
            ],
            "comment": "Low confidence category."
        },
        {
            "name": "Shiny",
            "units": [
                173,
                200
            ],
            "comment": "Units that seem to detect shiny, specular surfaces."
        },
        {
            "name": "Pattern",
            "units": [
                157,
                431,
                444,
                311,
                470,
                33,
                115,
                316,
                372
            ],
            "comment": "Low confidence category."
        },
        {
            "name": "Double Boundary",
            "units": [
                318,
                332,
                286,
                258,
                229,
                138,
                314,
            ],
            "comment": "Units that detect boundary transitions on two sides, with a 'foreground' texture in the middle."
        },
        {
            "name": "Bar / Line-Like",
            "units": [
                81,
                97,
                98,
                107,
                282,
                288,
                295,
            ],
            "comment": "Low confidence category.",
            "priority": -1
        },
        {
            "name": "Scales",
            "units": [
                461,
                466,
                90,
                478,
                419
            ],
            "comment": "We don't really understand these units."
        },
        {
            "name": "Proto-Head",
            "units": [
                362,
                413,
                334,
                331,
                174,
                225,
                393,
                185,
                435,
                180,
                441,
                163,
            ],
            "comment": "The tiny eye detectors, along with texture detectors for fur, hair and skin developed at the previous layer enable these early head detectors, which will continue to be refined in the next layer.",
            "priority": 1
        },
        {
            "name": "Bumps",
            "units": [
                167,
                206,
                312,
                292,
                194,
                140,
                223,
                254,
            ],
            "comment": "Low confidence category.",
            "priority": -1
        },
        {
            "name": "Color Contrast/Gradient",
            "units": [
                4,
                217,
                450,
                191,
                287,
                49,
                196,
                473,
                430,
                305,
                447,
                277,
                165,
                279,
                226,
                303,
                224,
                269,
                264,
                189,
                156,
                463,
                270,
                272,
            ],
            "comment": "Units which respond to different colors on each side. These units look for one color in the center, and another color surrounding it. These units likely have many subtleties about the range of hues, texture preferences, and interactions that similar neurons in earlier layers may not have. Compare to earlier color contrast (<a href='#group_conv2d0_color_contrast'><code>conv2d0</code></a>, <a href='#group_conv2d1_color_contrast'><code>conv2d1</code></a>, <a href='#group_conv2d2_color_contrast'><code>conv2d2</code></a>, <a href='group_mixed3a_color_contrast'><code>mixed3a</code></a>).",
            "priority": -1
        },
        {
            "name": "Boundary Misc",
            "units": [
                149,
                130,
                168,
                243,
                246,
                160,
                162,
            ],
            "comment": "Boundary-related units we didn't know what else to do with.",
            "priority": -1
        },
        {
            "name": "Star",
            "units": [
                263,
                262,
                205,
                135,
                94,
                304
            ],
            "comment": "Low confidence category.",
            "priority": -1
        },
        {
            "name": "Pointy",
            "units": [
                166,
                111,
            ],
            "comment": "Low confidence category.",
            "priority": -1
        },
        {
            "name": "Texture Contrast",
            "units": [
                319,
                155,
                201,
                171,
                178,
                197,
                260,
                412,
                248,
                250,
                241,
                390
            ],
            "comment": "Units that detect one texture on one side and a different texture on the other.",
            "priority": -1
        },
        {
            "name": "Other Units",
            "units": [
                43,
                45,
                58,
                78,
                85,
                86,
                96,
                100,
                127,
                134,
                137,
                142,
                145,
                146,
                148,
                150,
                154,
                179,
                181,
                187,
                188,
                207,
                213,
                214,
                222,
                231,
                235,
                240,
                253,
                265,
                266,
                268,
                273,
                306,
                350,
                354,
                358,
                371,
                391,
                399,
                411,
                433
            ],
            "comment": "Units that don't fall in any other category.",
            "priority": -2
        }
    ],
    "mixed4a": [
        {
            "name": "mixed4a_fur",
            "units": [
                223,
                244,
                287,
                232,
                27,
                81,
                263,
                462
            ],
            "comment": ""
        },
        {
            "name": "Oriented Dog Heads",
            "units": [
                308,
                222,
                234,
                325
            ],
            "comment": ""
        },
        {
            "name": "Other Dog Heads",
            "units": [
                199,
                498,
                240,
                295,
                201,
                268,
                368,
                246,
                207,
                300
            ],
            "comment": ""
        },
        {
            "name": "mixed4a_head_part",
            "units": [
                501,
                379,
                310,
                502,
                236,
                352,
                299
            ],
            "comment": ""
        },
        {
            "name": "mixed4a_body_posture",
            "units": [
                371
            ],
            "comment": ""
        },
        {
            "name": "mixed4a_any_boundary",
            "units": [
                400,
                356,
                290,
                253,
                356,
                254,
                233,
                255,
                265,
                307,
                374,
                212,
                388,
                344,
                210,
                224,
                327,
                213,
                330,
                239,
                247,
                267,
                229,
                252,
                262,
                286,
                259,
                261,
                273,
                285,
                206,
                279,
                293,
                296,
                329,
                348,
                312,
                200,
                215,
                230,
                197,
                372,
                359,
                105,
                353,
                204,
                375,
                349,
                284,
                340,
                408,
                292,
                294,
            ],
            "comment": ""
        },
        {
            "name": "mixed4a_curves",
            "units": [
                403,
                417,
                407,
                415,
                50,
                426,
                168,
                266,
            ],
            "comment": ""
        },
        {
            "name": "mixed4a_circles",
            "units": [
                402
            ],
            "comment": ""
        },
        {
            "name": "mixed4a_divot",
            "units": [
                298,
                358,
                362
            ],
            "comment": ""
        },
        {
            "name": "mixed4a_S",
            "units": [
                26,
                458
            ],
            "comment": ""
        },
        {
            "name": "mixed4a_watermark",
            "units": [
                328
            ],
            "comment": ""
        },
        {
            "name": "mixed4a_car",
            "units": [
                331,
                363
            ],
            "comment": ""
        },
        {
            "name": "mixed4a_high_low_2",
            "units": [
                245,
                93,
                392,
                301
            ],
            "comment": ""
        },
        {
            "name": "mixed4a_column_things",
            "units": [
                156,
                205,
                218,
                235,
                364,
                454,
                187,
                198
            ],
            "comment": ""
        },
        {
            "name": "mixed4a_complex_or_3d_shapes",
            "units": [
                397,
                398,
                399,
                401,
                406,
                409,
                410,
                411,
                412,
                418,
                429,
                421,
                423,
                324,
                425,
                428,
                429,
                430,
                431,
                432,
                433,
                434,
                436,
                437,
                438,
                440,
                441,
                442,
                443
            ],
            "comment": ""
        },
        {
            "name": "mixed4a_color_centers",
            "units": [
                128,
                178,
                170,
                180,
                185,
                5,
                33,
                24,
                10,
                121,
                107,
                118,
                35,
                47,
                19,
                122,
                174,
                154,
                123,
                49,
                76,
                90,
                155
            ],
            "comment": ""
        },
        {
            "name": "mixed4a_humans",
            "units": [
                55,
                391,
                333,
                489
            ],
            "comment": ""
        },
        {
            "name": "Animal related",
            "units": [
                42,
                59,
                60,
                71,
                77,
                100,
                166,
                192,
                220,
                221,
                242,
                260,
                272,
                282,
                318,
                337,
                338,
                355,
                357,
                367,
                383,
                395,
                444,
                460,
            ],
            "comment": ""
        },
        {
            "name": "Plants",
            "units": [
                459,
                461,
                475
            ],
            "comment": ""
        },
        {
            "name": "Complex Shapes and Curvature",
            "units": [
                397,
                398,
                399,
                401,
                406,
                409,
                410,
                411,
                412,
                418,
                429,
                421,
                423,
                324,
                425,
                428,
                429,
                430,
                431,
                432,
                433,
                434,
                436,
                437,
                438,
                440,
                441,
                442,
                443,
                403,
                417,
                407,
                415,
                50,
                426,
                168,
                266,
                26,
                458
            ],
            "comment": ""
        }
    ],
    "mixed4b":
        [
            {
                "name": "Fur boundary / posture",
                "units": [431, 443, 416,
                    412, 429, 414, 384,
                    425, 408, 436, 397, 406, 398, 422, 404, 199, 420, 393, 439, 434, 401],
                "comment": ""
            },

            {
                "name": "Human boundary / posture",
                "units": [391, 411, 405, 44, 475],
                "comment": ""
            },
            {
                "name": "Dog Heads",
                "units": [270, 328, 329, 400, 402, 403, 409, 418, 345, 325, 327, 336, 324, 339, 327, 370, 260, 165,],
                "comment": ""
            },
            {
                "name": "Square Things",
                "units": [474, 467, 169, 202, 237],
                "comment": ""
            },
            {
                "name": "Curves",
                "units": [482, 426, 253, 226, 193, 142, 96, 502],
                "comment": ""
            },
            {
                "name": "Color Center-Surround",
                "units": [90, 88, 80, 5, 72, 26, 120, 149, 139],
                "comment": ""
            },
            {
                "name": "Evolute / Diverge",
                "units": [39],
                "comment": ""
            },
            {
                "name": "Counting?",
                "units": [256,],
                "comment": ""
            },
            {
                "name": "Car Parts",
                "units": [237, 491, 373],
                "comment": ""
            },
            {
                "name": "Cat Relevant",
                "units": [175, 172, 207, 176],
                "comment": ""
            },
            {
                "name": "Dog Relevant",
                "units": [175, 418, 429, 443, 439],
                "comment": ""
            },
            {
                "name": "Race Car Relevant",
                "units": [217, 237, 209, 373, 179],
                "comment": ""
            },
            {
                "name": "Fire Truck Relevant",
                "units": [217, 373, 209, 179],
                "comment": ""
            },
        ],
    "mixed4c":
        [
            {
                "name": "Car",
                "units": [447,],
                "comment": ""
            },
            {
                "name": "Head shifted / Oriented / boundary",
                "units": [394, 387, 388, 418, 416, 414, 410, 409, 407, 406, 392, 390, 178, 160, 398],
                "comment": ""
            },
            {
                "name": "Counting? Gap between objects?",
                "units": [415,],
                "comment": ""
            },
            {
                "name": "Curves",
                "units": [190, 321,],
                "comment": ""
            },
            {
                "name": "Color Center-Surround",
                "units": [11, 23, 76, 104, 107, 43],
                "comment": ""
            },
            {
                "name": "Cat Relevant",
                "units": [159, 471, 216, 391],
                "comment": ""
            },
            {
                "name": "Dog Relevant",
                "units": [264, 216, 172, 415, 414],
                "comment": ""
            },
            {
                "name": "Race Car Relevant",
                "units": [434, 447, 78, 87, 143],
                "comment": ""
            },
            {
                "name": "Fire Truck Relevant",
                "units": [78, 434, 447, 143],
                "comment": ""
            },
        ],
    "mixed4d":
        [
            {
                "name": "Dog Ears",
                "units": [426, 436,],
                "comment": ""
            },
            {
                "name": "Person Beside Dog",
                "units": [429, 427],
                "comment": ""
            },
            {
                "name": "Person",
                "units": [432, 458, 354, 227],
                "comment": ""
            },
            {
                "name": "Cat Relevant",
                "units": [221, 403, 401, 461],
                "comment": ""
            },
            {
                "name": "Dog Relevant",
                "units": [403, 446, 477, 431],
                "comment": ""
            },
            {
                "name": "Race Car Relevant",
                "units": [169, 352, 455, 484, 141],
                "comment": ""
            },
            {
                "name": "Fire Truck Relevant",
                "units": [419, 417, 169, 352],
                "comment": ""
            },
        ],
        "mixed4e":
        [
            {
                "name": "Cat Relevant",
                "units": [809, 819, 783, 766],
                "comment": ""
            },
            {
                "name": "Dog Relevant",
                "units": [615, 759, 36, 763],
                "comment": ""
            },
            {
                "name": "Race Car Relevant",
                "units": [804, 796, 785, 741],
                "comment": ""
            },
            {
                "name": "Fire Truck Relevant",
                "units": [756, 591, 632, 804],
                "comment": ""
            },
        ],
        "mixed5a":
        [
            {
                "name": "Cat Relevant",
                "units": [219, 748, 352, 81],
                "comment": ""
            },
            {
                "name": "Dog Relevant",
                "units": [1, 713, 650, 101],
                "comment": ""
            },
            {
                "name": "Race Car Relevant",
                "units": [150, 464, 425, 432],
                "comment": ""
            },
            {
                "name": "Fire Truck Relevant",
                "units": [150, 256, 581, 432, 593],
                "comment": ""
            },
        ],
        "mixed5b":
        [
            {
                "name": "Cat Relevant",
                "units": [26, 76, 71, 428],
                "comment": ""
            },
            {
                "name": "Dog Relevant",
                "units": [21, 140, 192, 237, 799, 730],
                "comment": ""
            },
            {
                "name": "Race Car Relevant",
                "units": [513, 614, 204, 264],
                "comment": ""
            },
            {
                "name": "Fire Truck Relevant",
                "units": [661, 876, 881, 513, 890, 11],
                "comment": ""
            },
        ]
}

export function getInterestiingNeurons(layer) {
    const layerEntry = interestingNeurons[layer];
    const names = layerEntry.map(entry => {
        return entry.name;
    });
    const units = {};
    layerEntry.forEach(entry => {
        units[entry.name] = entry.units;
    });
    return {"names": names, "units": units};
}