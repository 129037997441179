
// adapted from https://gist.github.com/caisq/33ed021e0c7b9d0e728cb1dce399527d

///import {tf} from '@tensorflow/tfjs' ;
const tf  = require('@tensorflow/tfjs');

/**
 * Define a custom layer.
 *
 * This custom layer is written in a way that can be saved and loaded.
 */
class LRN extends tf.layers.Layer {
    constructor(config) {
      super(config);
      this.depthRadius = config.n;
      this.bias = config.k;
      this.alpha = config.alpha;
      this.beta = config.beta;
    }

    /**
     * build() is called when the custom layer object is connected to an
     * upstream layer for the first time.
     * This is where the weights (if any) are created.
     */
    build(inputShape) {
    }
  
    /**
     * call() contains the actual numerical computation of the layer.
     *
     * It is "tensor-in-tensor-out". I.e., it receives one or more
     * tensors as the input and should produce one or more tensors as
     * the return value.
     *
     * Be sure to use tidy() to avoid WebGL memory leak. 
     */
    call(input) {
      return tf.tidy(() => {
        const x = tf.localResponseNormalization(
            input[0], this.depthRadius, this.bias, this.alpha, this.beta);
        return x;
      });
    }
  
    /**
     * getConfig() generates the JSON object that is used
     * when saving and loading the custom layer object.
     */
    getConfig() {
      const config = super.getConfig();
      Object.assign(config, {n: this.depthRadius, k: this.bias,
         alpha: this.alpha, beta: this.beta});
      return config;
    }
    
    /**
     * The static className getter is required by the 
     * registration step (see below).
     */
    static get className() {
      return 'LRN';
    }
  }
  /**
   * Regsiter the custom layer, so TensorFlow.js knows what class constructor
   * to call when deserializing an saved instance of the custom layer.
   */
  tf.serialization.registerClass(LRN);

  var exports = {LRN: LRN};

  export default exports;