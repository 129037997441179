import * as tf from '@tensorflow/tfjs'

import {TuningCurveResult2D} from './Components/TuningCurveComponents/TuningCurveResult2D'
import {TC_MOSAIC_SIZE} from './config'
import {Inferencer, outputTypes, LogitOutput} from './Model/Outputs'


export function handleModel(
    model, sceneCanvasWidth, animation, data, width, height, asynchronous) {
    if (model.isLoaded()) {
        const outputSpec = model.outputManager.getOutputSpec(sceneCanvasWidth, model);
        tf.tidy(() => {
            const inferencer = new Inferencer(outputSpec, model.sourceModel);
            let metaData;
            if (animation) {
                metaData = {
                    animationFrame: animation.frame,
                    inputImage: { data: data, width: width, height: height }
                };
            }
            inferencer.infer(data, metaData, asynchronous);
        });
    }
}

export function handleTuningCurveData(
    tuningCurveResults,
    callbackResult, saveTCThumbs,
    saveData, saveRawImages) {
    const {animation, inferenceData, activationParams, paramNumber,
        paramIndex, metaData} = callbackResult;
    const {inputImage, animationFrame} = metaData;
    if(! (paramIndex in tuningCurveResults)) {
        tuningCurveResults[paramIndex] =
        new TuningCurveResult2D( animation,
            animation.resolution, activationParams,
            activationParams.neurons, TC_MOSAIC_SIZE);
    }
    tuningCurveResults[paramIndex].resolution = animation.resolution;
    if(animationFrame === 0){
        if(paramIndex === 0) {
            tuningCurveResults[paramIndex].resetZip();
        }
        tuningCurveResults[paramIndex].clearCanvas();
    }
    tuningCurveResults[paramIndex].putImage(
        animationFrame, inputImage, inferenceData
    );
    if(saveRawImages){
        tuningCurveResults[paramIndex].persistImage(
            inputImage, animationFrame);
    }
    if(animationFrame === animation.maxFrames()-1) {
        if(saveData) {
            tuningCurveResults[paramIndex].persistInferenceData(paramIndex);
            if(saveTCThumbs) {
                tuningCurveResults[paramIndex].persistTuningCurve(paramIndex);
            }
            if(paramIndex === paramNumber-1) {
                tuningCurveResults[paramIndex].downloadZip();
                Object.entries(tuningCurveResults).forEach(([key, val]) => {
                    //delete tuningCurveResults[key];
                });
            }
        }
    }
    return tuningCurveResults;
}

export function handleAnimation(animation, setAnimation, update) {
    if(animation) {
        animation.advanceFrame();
        if(animation.hasEnded()) {
            setAnimation(null);
            update.setApplyGuiSceneParams = true;
            console.log("update.setApplyGuiSceneParams = true;")
        }
    }
}