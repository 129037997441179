import React, {useState, useMemo, useRef, useEffect} from 'react'
import {tf} from '../../TFJS'
import {getInput, getNeuronObjective, getChannelObjective, getTransformF, drawPixelsToCanvas, deprocessImage, getLoss} from './FeatureVisUtil'
import {FeatureVisHandler} from './FeatureVisHandler'

export function FeatureVisNeuronView(props) {
    const {model, layer, neuron, neuronWidth, usePriorVis, optimize,
        startIterating, stopIterating, reset} = props;
    const resolution = 128;
    const pyrLayers = usePriorVis ? 5 : 1;

    const canvasRef = useRef();

    const objectiveF = (input) => {
        if(model.sourceModel) {
            return getNeuronObjective(model.sourceModel, layer, neuron, resolution)(input);
        } else {
            return tf.ones([1,1,1,1]);
        }
    }

    const transformF = useMemo(() => {
        return getTransformF();
    }, []);

    const featureVisHandler = useMemo(() => {
        console.log("creating feature vis handler")
        const input = getInput(resolution, pyrLayers, true);
        const [pyramidF, pyramidLayers] = input;
        const lossF = (layers) => () => getLoss(layers, pyramidF, objectiveF, transformF);
        const deprocessF = (layers) => deprocessImage(pyramidF(layers));
        const ret = new FeatureVisHandler(lossF, deprocessF, pyramidLayers, canvasRef, resolution);
        return ret;
    }, [pyrLayers, resolution, layer, neuron, model]);

    if(startIterating) {
        featureVisHandler.doSteps(1000000);
    } 
    if(stopIterating) {
        featureVisHandler.forceStop();
    }
    if(reset) {
        const input = getInput(resolution, pyrLayers, true);
        const [pyramidF, pyramidLayers] = input;
        featureVisHandler.reset(pyramidLayers);
    }

    return  <canvas ref={canvasRef} width={neuronWidth} height={neuronWidth} style={{ top:0, left:0, background:"gray" }}>
        </canvas>
}