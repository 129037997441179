import React, {useState, useMemo} from 'react'
import { ModelControlView } from './ModelControlView'
import {ActivationCanvas} from '../ActivationComponents/ActivationRenderer'
import {getDefaultActivations, getDefGenerators} from '../ActivationViewUtil'
import {modelTypes} from '../../Model/ModelMetaInfo'
import {getInterestiingNeurons} from './InterestingNeurons'
import { FeatureVisRow} from '../FeatureVisComponents/FeatureVisRow'
import {CombinedActivationView} from './CombinedActivationView'
import {ListItem, ListItemText, FormControlLabel, Checkbox,
Collapse, Button, Tooltip} from '@material-ui/core'
import {Menu, MenuOpen} from '@material-ui/icons'
import {CustomSlider} from '../ParamControls/CustomSlider'
import {ttCentralViewOptions, ttCentralView} from '../../strings'

export function CentralView(props) {
    const {modelManager, neuronWidth, setNeedsUpdate,
    activations, setActivations, showHelp} = props;

    const [modelTrainTypes, setModelTrainTypes] = useState([modelTypes.STD, modelTypes.ADV]);
    const [modelIterations, setModelIterations] = useState([0, 17]);
    const [targetLayer, setTargetLayer] = useState("mixed4a");
    const [neuronGroup, setNeuronGroup] = useState(null);
    const [preRelu, setPreRelu] = useState(true);
    const [neuronCycleOffset, setNeuronCycleOffset] = useState(0);
    const [naiveParam, setNaiveParam] = useState(false);
    const [showActivations, setShowActivations] = useState(true);
    const [showOptions, setShowOptions] = useState(false);
    const [dragging, setDragging] = useState(false);
    const [maxNeurons, setMaxNeurons] = useState(4);
    const [normalization, setNormalization] = useState(0);

    let wrappedTargetLayer = targetLayer;
    if(preRelu) {
        if(targetLayer.includes("mixed")){
            wrappedTargetLayer += "_pre_relu";
        }
    }

    const interestingNeurons = getInterestiingNeurons(targetLayer);
    const wrappedNeuronGroup = useMemo(() => {
        if(neuronGroup === null) {
            setNeuronGroup(interestingNeurons.names[0])
            return interestingNeurons.names[0];
        } else {
            return neuronGroup;
        }
    }, [neuronGroup]);

    const nNeurons = interestingNeurons.units[wrappedNeuronGroup].length;
    const displayedNeurons = [];
    for(let i=neuronCycleOffset;
        i<neuronCycleOffset+Math.min(maxNeurons, nNeurons);
        i++) {
        const index = (maxNeurons*1000+ i) % nNeurons;
        displayedNeurons.push(
            interestingNeurons.units[wrappedNeuronGroup][index]
        );
    }

    const canvasWidth = neuronWidth * Math.min(maxNeurons, displayedNeurons.length);

    const dummyActivationParams = {
        0: {neurons: displayedNeurons, layer:wrappedTargetLayer, normalize: normalization, neuronOffset:neuronCycleOffset, totalNeurons:nNeurons},
        1: {neurons: displayedNeurons, layer:wrappedTargetLayer, normalize: normalization, neuronOffset:neuronCycleOffset, totalNeurons:nNeurons},
        2: {neurons: displayedNeurons, layer:wrappedTargetLayer, normalize: normalization, neuronOffset:neuronCycleOffset, totalNeurons:nNeurons}
    };

    const normString = normalization == 0 ? 'layer stdev.' : Math.floor(Math.pow(10, normalization));

    return <div>
        <div className="row" style={{alignItems:"center"}}>
            <div style={{flex:"1 1 auto"}}>
            <Tooltip title={showHelp ? ttCentralView : ""}>
            <ListItem>
                <ListItemText primary="Activations / Feature Vis" />
            </ListItem>
            </Tooltip>
            </div>
            <div className="row" style={{flex:"0 1 auto"}}>
            <Tooltip title={showHelp ? ttCentralViewOptions : ""}>
                <Button onClick={() => {
                    setShowOptions(!showOptions);
                }}>
                    <Menu />
                </Button>
                </Tooltip>
            </div>
        </div>
        
        <Collapse in={showOptions} timeout="auto">
            <div className="column" style={{padding: 5}}>
                <div className="row">
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={naiveParam}
                                onChange={() => {
                                    setNaiveParam(!naiveParam);
                                }}
                                name="checkedB"
                                color="primary"
                            />
                        }
                        label="Naive Parametrization"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={showActivations}
                                onChange={() => {
                                    setShowActivations(!showActivations);
                                    setNeedsUpdate(true);
                                }}
                                name="checkedB"
                                color="primary"
                            />
                        }
                        label="Show Activations"
                    />
                </div>
                <div className="row">
                    <CustomSlider
                        labelText="# of Neurons"
                        valueLabelDisplay={"auto"}
                        step={1}
                        min={1}
                        max={8}
                        value={maxNeurons}
                        onChange={(evt, value) => {
                            setMaxNeurons(value);
                            setNeedsUpdate(true);
                        }}
                    />
                </div>
                <div className="row">
                    <CustomSlider
                        labelText={"Act. Normalization: " + normString}
                        valueLabelDisplay={"auto"}
                        step={0.02}
                        min={0}
                        max={3}
                        value={normalization}
                        onChange={(evt, value) => {
                            setNormalization(value);
                            setNeedsUpdate(true);
                        }}
                    />
                </div>
            </div>
        </Collapse>
        <div className="row" style={{position:"relative"}}>
            <CombinedActivationView
            modelManager={modelManager}
            modelIterations={modelIterations}
            modelTrainTypes={modelTrainTypes}
            activationParams={dummyActivationParams}
            neuronWidth={neuronWidth}
            totalWidth={canvasWidth}
            activations={activations}
            setActivations={setActivations}
            setNeedsUpdate={setNeedsUpdate}
            naiveParam={naiveParam}
            showActivations={showActivations}
            onCycleNeuronsUp={(() => {
                setNeuronCycleOffset(neuronCycleOffset+1);
                setNeedsUpdate(true);
            })}
            onCycleNeuronsDown={(() => {
                setNeuronCycleOffset(neuronCycleOffset-1);
                setNeedsUpdate(true);
            })}
            dragging={dragging}
            showHelp={showHelp}
            />
        </div>
        <div className="row ">
            <ModelControlView
            modelTrainTypes={modelTrainTypes}
            setModelTrainTypes={(modelTrainTypes) => {
                setModelTrainTypes(modelTrainTypes);
            }}
            modelIterations={modelIterations}
            setModelIterations={(modelIterations) => {
                setModelIterations(modelIterations);
            }}
            targetLayer={targetLayer}
            setTargetLayer={(targetLayer) => {
                setTargetLayer(targetLayer);
                setNeuronCycleOffset(0);
            }}
            neuronGroup={wrappedNeuronGroup}
            setNeuronGroup={(neuronGroup) => {
                setNeuronGroup(neuronGroup);
                setNeuronCycleOffset(0);
            }}
            setDragging={setDragging}
            {...props}/>
        </div>
    </div>
}