import React, { useRef, useMemo } from 'react';
import { extend, useThree, useFrame } from 'react-three-fiber';
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls';

extend({ OrbitControls });

export function Controls(props) {
  const {onChange} = props;
  const controlsRef = useRef();
  const listenerRegistered = useRef();
  const { camera, gl } = useThree();

  const gl_context = gl.domElement.getContext("webgl");

  useMemo(() => {
    if(controlsRef.current) {
      controlsRef.current.addEventListener( 'change', onChange );
      listenerRegistered.current = true;
    }
  },[controlsRef.current]);


  useFrame(() => {
    controlsRef.current && controlsRef.current.update();
    if(controlsRef.current && !listenerRegistered.current===true) {
      controlsRef.current.addEventListener( 'change', onChange );
      listenerRegistered.current = true;
    }
});

  return (
    <orbitControls
      screenSpacePanning={true}
      ref={controlsRef}
      args={[camera, gl.domElement]}
      enableRotate
      enablePan={true}
      maxDistance={100}
      minDistance={.3}
    />
  );
}