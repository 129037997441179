import React from 'react'

function tanh(x){
    return 1 - 2/(Math.exp(2*x)+1);
}

export function getTestLogitActivations() {
    const testLayerActivatons = [];
    for(let i=0; i<500; i++) {
        testLayerActivatons.push(tanh((i-250)/10));
    }
    return {data: testLayerActivatons};
}


export function getHoveredClassnameTooltip(classname, left, top) {
    return(<div className="row" style={{background:'white', position:'absolute',
    left:left, top: top, pointerEvents:'none', padding:5}}>
        {classname}
    </div>);
}
