import {Matrix4} from 'three'

const identity = new Matrix4();

export default {
    uniforms: {
        tDiffuse: { type: 't', value: null},
        tGauss: { type: 't', value: null},
        kernelLookups: {value: 125},
        sigma: {value: 0.0},
        vertical: {value: 0.0},
    },
  
    vertexShader: [
        'varying vec2 vUV;',
        'void main(void) {',
        '    gl_Position = projectionMatrix * viewMatrix * modelMatrix * vec4(position, 1.0);',
        '    vUV = uv;',
        '}'
    ].join('\n'),
  
    fragmentShader: [
        'precision highp float;',
        'precision highp sampler2D;',
        'uniform sampler2D tDiffuse;',
        'uniform sampler2D tGauss;',
        'uniform int kernelLookups;',
        'uniform float sigma;',
        'uniform float vertical;',
        'varying vec2 vUV;',
        
        'void main(void) {',
        '    vec3 col = vec3(0.0);',
        '    float gaussU = 0.0;',
        '    float eps = 1.0/float(kernelLookups-1);',
        '    float sigmaU = -3.0*sigma;',
        '    float sigmaEps = 6.0*sigma/float(kernelLookups-1);',
        '    float normalizer = 0.0;',
        '    vec3 temp = texture(tDiffuse, vUV).rgb;',
        '    for(int i=0; i<kernelLookups; i++) {',
        '        float gaussMult = texture(tGauss, vec2(gaussU, 0.5)).r;',
        '        vec2 texUV = vec2(vUV.x+(1.0-vertical)*sigmaU, vUV.y+(vertical)*sigmaU);',
        '        vec4 texVal = texture(tDiffuse, texUV);',
        '        col += gaussMult*texVal.rgb;',
        '        normalizer += gaussMult;',
        '        gaussU += eps;',
        '        sigmaU += sigmaEps;',
        '        temp = texture(tGauss, vec2(vUV.x, 0.5)).rgb*100.0;',
        '    }',
        '    vec3 color = col/normalizer;',
            
        '    gl_FragColor = vec4(color, 1.0);',
        '}'
    ].join('\n')
  }