import React, { useState, useRef } from 'react'
import { Typography } from '@material-ui/core'

import {ClassificationOverlay} from './ClassificationOverlay'

export function IterativeProcessingOverlay(props) {
    /**
     * provides transparent clickable element for handling scene overlay display
     * as well as classification information
     */
    const { model, overlayImage, handleMouseDown, width, height } = props;

    let origClsName, currClsName, origProb, currOrigProb, currProb;
    if (overlayImage) {
        const { startPred, currentPred } = overlayImage;
        const { prob, cls, origClsProb } = currentPred;
        const origCls = startPred.cls;
        origClsName = model.getClassName(origCls);
        currClsName = model.getClassName(cls);
        origProb = startPred.prob.toFixed(3);
        currOrigProb = origClsProb.toFixed(3);
        currProb = prob.toFixed(3);
    }
    const rStyle = {
        position: 'absolute', width: "100%", height: "25%", padding: 5,
        left: 0, bottom: 0, background: "white", opacity: 0.5,
    };

    const getTypographyRow = (content, fontSize) => {
        let style={};
        if(fontSize){
            style["fontSize"] = fontSize;
        }
        return<div className="row" >
            <Typography variant="body1" style={style}>{content}</Typography>
        </div>
    }

    return <div style={{
        position: 'absolute', width: width, height: height,
        visibility: overlayImage ? 'visible' : 'hidden'
    }}
        onMouseDown={handleMouseDown}>
        <ClassificationOverlay model={model} overlayImage={overlayImage}/>
    </div>
}