import { Vector3} from 'three'
import {getRotMats} from '../SceneViewUtil'

export const tuningCurveParams = {
  NO_CHANGE: "No Change",
  CAMERA_PITCH: "Camera Pitch",
  CAMERA_YAW: "Camera Yaw",
  CAMERA_ROLL: "Camera Roll",
  SHADING_LIGHT_INFLUENCE: "Light Influence",
  SHADING_TEXTURE_INFLUENCE: "Texture Influence",
  SHADING_TEXTURE_BLUR: "Texture Blur",
  POST_ALPHA: "Alpha",
  POST_SATURATION: "Saturation",
  BG_BLUR: "Background Blur",
  SHAPE_CATNESS: "Shape Catness",
  TEXTURE_CATNESS: "Texture Catness",
  ADV_ALPHA: "Adversarial Alpha", 
  ADV_ORIG_ALPHA: "Orig. Alpha",
  FREQ_CUTOFF: "Frequency Cutoff", 
  FREQ_LOW_SIGMA: "Low Sigma", 
  FREQ_HIGH_SIGMA: "High Sigma"
}

export const sampleAnimationJson = {
  xAxis: {
      param: tuningCurveParams.CAMERA_YAW,
      rangeMin: -1.0,
      rangeMax: 1.0
  },
  yAxis: {
      param: tuningCurveParams.SHADING_TEXTURE_INFLUENCE,
      rangeMin: 0,
      rangeMax: 1.0
  },
  resolution: 10
}

export function setRenderParam(renderParams, key, value) {
    switch(key) {
      case tuningCurveParams.CAMERA_PITCH:
        renderParams.pitch = value;
        break;
      case tuningCurveParams.CAMERA_ROLL:
        renderParams.roll = value;
        break;
      case tuningCurveParams.CAMERA_YAW:
        renderParams.yaw = value;
        break;
      case tuningCurveParams.SHADING_LIGHT_INFLUENCE:
        renderParams.light = value;
        break;
      case tuningCurveParams.SHADING_TEXTURE_INFLUENCE:
        renderParams.texture = value;
        break;      
      case tuningCurveParams.SHADING_TEXTURE_BLUR:
        renderParams.textureBlur = value;
        break;
      case tuningCurveParams.POST_ALPHA:
        renderParams.alpha = value;
        break;
      case tuningCurveParams.POST_SATURATION:
        renderParams.saturation = value;
        break;
      case tuningCurveParams.BG_BLUR:
        renderParams.bgBlur = value;
        break;
      case tuningCurveParams.SHAPE_CATNESS:
        renderParams.shapeCatness = value;
        break;
      case tuningCurveParams.TEXTURE_CATNESS:
        renderParams.textureCatness = value;
        break;
      case tuningCurveParams.ADV_ALPHA:
        renderParams.advAlpha = value;
        break;
      case tuningCurveParams.ADV_ORIG_ALPHA:
        renderParams.advOrigAlpha = value;
        break;
      case tuningCurveParams.FREQ_CUTOFF:
        renderParams.cutoffSigma = value;
        break;
      case tuningCurveParams.FREQ_LOW_SIGMA:
        renderParams.lowSigma = value;
        break;
      case tuningCurveParams.FREQ_HIGH_SIGMA:
        renderParams.highSigma = value;
        break;
      default:
        break;
    }
  }
  
export function setUniformsFromRenderParams(uniforms, bgUniforms, renderParams) {
    const TO_RAD = 1/180*Math.PI;
    const p = 'pitch' in renderParams ? TO_RAD*renderParams.pitch : 0;
    const y = 'yaw' in renderParams ? TO_RAD*renderParams.yaw : 0;
    const r = 'roll' in renderParams ? TO_RAD*renderParams.roll : 0;

    const [preRot, postRot] = getRotMats(new Vector3(p, y, r));

    uniforms.preRot = preRot;
    uniforms.postRot = postRot;

    if('texture' in renderParams) {
      uniforms.textureInfluence = renderParams.texture;
    }
    if('textureBlur' in renderParams) {
      uniforms.lod = renderParams.textureBlur;
    }
    if('light' in renderParams) {
      uniforms.lightingInfluence = renderParams.light;
    }
    if('alpha' in renderParams) {
      uniforms.alpha = renderParams.alpha;
    }
    if('saturation' in renderParams) {
      uniforms.saturation = renderParams.saturation;
    }
    if('shapeCatness' in renderParams) {
      uniforms.shapeCatness = renderParams.shapeCatness;
    }
    if('textureCatness' in renderParams) {
      uniforms.textureCatness = renderParams.textureCatness;
    }
    if('bgBlur' in renderParams) {
      bgUniforms.lod = renderParams.bgBlur;
    }
  }