export default {
    uniforms: {
        tDiffuse1: { type: 't', value: null},
        tDiffuse2: { type: 't', value: null},
        alpha1: {value: 1.0},
        alpha2: {value: 1.0},
        hue1: {value: 0.0},
        hue2: {value: 0.0}
    },
  
    vertexShader: [
		"varying vec2 vUv;",

		"void main() {",

		"	vUv = uv;",
		"	gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );",

		"}"
    ].join('\n'),
  
    fragmentShader: [
        "uniform float alpha1;",
        "uniform float alpha2;",
        'uniform float hue1;', 
        'uniform float hue2;',

        "uniform sampler2D tDiffuse1;",
        "uniform sampler2D tDiffuse2;",

    "varying vec2 vUv;",
    

    '//https://gist.github.com/sugi-cho/6a01cae436acddd72bdf',
    'vec3 rgb2hsv(vec3 c)',
    '{',
        'vec4 K = vec4(0.0, -1.0 / 3.0, 2.0 / 3.0, -1.0);',
        'vec4 p = mix(vec4(c.bg, K.wz), vec4(c.gb, K.xy), step(c.b, c.g));',
        'vec4 q = mix(vec4(p.xyw, c.r), vec4(c.r, p.yzx), step(p.x, c.r));',

        'float d = q.x - min(q.w, q.y);',
        'float e = 1.0e-10;',
        'return vec3(abs(q.z + (q.w - q.y) / (6.0 * d + e)), d / (q.x + e), q.x);',
    '}',

    'vec3 hsv2rgb(vec3 c)',
    '{',
        'vec4 K = vec4(1.0, 2.0 / 3.0, 1.0 / 3.0, 3.0);',
        'vec3 p = abs(fract(c.xxx + K.xyz) * 6.0 - K.www);',
        'return c.z * mix(K.xxx, clamp(p - K.xxx, 0.0, 1.0), c.y);',
    '}',

		"void main() {",

    "	vec3 texel1 = texture2D( tDiffuse1, vUv ).rgb;",
    "	vec3 texel2 = texture2D( tDiffuse2, vUv ).rgb;",
    " vec3 hsv1 = rgb2hsv(texel1);",
    " vec3 hsv2 = rgb2hsv(texel2);",
    " hsv1.x += hue1;",
    " hsv2.x += hue2;",
    " vec3 rgb1 = hsv2rgb(hsv1);",
    " vec3 rgb2 = hsv2rgb(hsv2);",
		"	gl_FragColor = vec4(alpha1 * rgb1 + (1.0-alpha1)*vec3(0.5) + alpha2 * rgb2, 1.0);",

		"}"
    ].join('\n')
  }