import React, {useState} from 'react'
import { getNeuronThumbURL} from '../../Model/ModelMetaInfo'
import {FeatureVisNeuronView} from './FeatureVisNeuronView'
import {PlayButtonGroup} from './PlayButtonGroup'
import './FeatureVisRow.css'
import { useEffect } from 'react'
import { RGBAFormat } from 'three'

export function FeatureVisRow(props) {
    const {model, params, neuronWidth, usePriorVis} = props;
    const [isOptimizing, setIsOptimizing] = useState(false);
    const [startOptimizing, setStartOptimizing] = useState(false);
    const [stopOptimizing, setStopOptimizing] = useState(false);
    const [reset, setReset] = useState(false);

    useEffect(() => {
        if(startOptimizing) {
            setStartOptimizing(false);
        }
        if(stopOptimizing) {
            setStopOptimizing(false);
        }
        if(reset) {
            setReset(false);
        }
    }, [startOptimizing, stopOptimizing, reset]);

    if (!model.isMixedModel) {
        console.log("asdf", params.neurons)
        return params.neurons.map((n, i) => {
            let r = (params.neuronOffset+i)%params.totalNeurons;
            r = r < 0 ? r + params.totalNeurons : r;
            const src = getNeuronThumbURL(model, params.layer, n, usePriorVis);
            return (
                <div style={{position:"relative", borderBottomRightRadius:"13px", borderBottomLeftRadius:"13px", overflow:"hidden", height:neuronWidth}}>
                    <img width={neuronWidth} height={neuronWidth} src={src}></img>
                    <div style={{position:"absolute", zIndex: 10, padding:3, top:0, left:0,
                    backgroundColor:"white", fontFamily: "arial", fontSize: "12px", opacity:0.5,
                    borderRadius:"5px",
                }}>
                        {(r+1) + " / " + params.totalNeurons + " (" + n + ")"}
                    </div>
                </div>
            )
        });
    } else {

        return <div className="row" style={{position: "relative"}}>
            {params.neurons.map((n, i) => {
                const src = getNeuronThumbURL(model, params.layer, n, usePriorVis);
                /*return (
                    <img width={neuronWidth} height={neuronWidth} src={src}></img>
                )*/
                return (
                    <div style={{borderBottomRightRadius:"13px", borderBottomLeftRadius:"13px", overflow:"hidden", height:neuronWidth, width:neuronWidth}}>
                        <FeatureVisNeuronView
                        model={model}
                        layer={params.layer}
                        neuron={n}
                        neuronWidth={neuronWidth}
                        usePriorVis={usePriorVis}
                        startIterating={startOptimizing}
                        stopIterating={stopOptimizing}
                        reset={reset}/>
                    </div>
                )
            })}
            <div className="playbutton-parent" style={{position:"absolute", top:0, left:0,
            zIndex:100, width:"100%", height:"100%",  display:"flex",
            justifyContent:"center", alignItems:"center"}}>
                <PlayButtonGroup 
                isPlaying={isOptimizing}
                onPlay={() => {
                    setStartOptimizing(true);
                    setIsOptimizing(true);
                }}
                onPause={() => {
                    setStopOptimizing(true);
                    setIsOptimizing(false);
                }}
                onReset={() => {
                    setReset(true);
                    setStopOptimizing(true);
                    setIsOptimizing(false);
                }}/>
            </div>

        </div>
    }
}