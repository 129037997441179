/**
 * based on 'https://threejsfundamentals.org/threejs/resources/threejs/r115/examples/jsm/postprocessing/BloomPass.js';
 */

import {
	AdditiveBlending,
	LinearFilter,
	RGBAFormat,
	ShaderMaterial,
	UniformsUtils,
	Vector2,
	WebGLRenderTarget,
	FloatType
} from "three";
import { Pass } from "three/examples/jsm/postprocessing/Pass";
import ComposeShader from './Shaders/ComposeShader'
import GaussianBlurShader from './Shaders/GaussianBlurShader'

var FrequencyDecompPass = function ( resolution ) {

	Pass.call( this );

	this.resolution = ( resolution !== undefined ) ? resolution : 256;

	this.cutoffSigma = 0.0;
	this.lowSigma = 0.0;
	this.highSigma = 0.0;
	this.lowHue = 0.0;
	this.highHue = 0.0;
	this.lowAlpha = 1.0;
	this.highAlpha = 1.0;

	this.cutoffKernel = null;
	this.lowKernel = null;
	this.highKernel = null;
	this.cutoffLookups = 125;
	this.lowLookups = 125;
	this.highLookups = 125;

	// render targets

	var pars = { minFilter: LinearFilter, magFilter: LinearFilter, type: FloatType, format: RGBAFormat };

	this.renderTargetX = new WebGLRenderTarget( resolution, resolution, pars );
	this.renderTargetX.texture.name = "FrequencyDecompPass.x";
	this.renderTargetY = new WebGLRenderTarget( resolution, resolution, pars );
	this.renderTargetY.texture.name = "FrequencyDecompPass.y";

	this.renderTargetHigh = new WebGLRenderTarget( resolution, resolution, pars );
	this.renderTargetHigh.texture.name = "FrequencyDecompPass.high";

	// copy material

	if ( ComposeShader === undefined )
		console.error( "FrequencyDecompPass relies on ComposeShader" );

	var composeShader = ComposeShader;

	this.composeUniforms = UniformsUtils.clone( composeShader.uniforms );

	this.materialCompose = new ShaderMaterial( {

		uniforms: this.composeUniforms,
		vertexShader: composeShader.vertexShader,
		fragmentShader: composeShader.fragmentShader
	} );

	// convolution material

	if ( GaussianBlurShader === undefined )
		console.error( "FrequencyDecompPass relies on GaussianBlurShader" );

	var gaussianBlurShader = GaussianBlurShader;

	this.gaussianBlurUniforms = UniformsUtils.clone( gaussianBlurShader.uniforms );

	this.materialGaussianBlur= new ShaderMaterial( {

		uniforms: this.gaussianBlurUniforms,
		vertexShader: gaussianBlurShader.vertexShader,
		fragmentShader: gaussianBlurShader.fragmentShader

	} );

	this.fsQuad = new Pass.FullScreenQuad( null );

};

FrequencyDecompPass.prototype = Object.assign( Object.create( Pass.prototype ), {

	constructor: FrequencyDecompPass,

	render: function ( renderer, writeBuffer, readBuffer, /*deltaTime, maskActive*/ ) {

		if(this.cutoffSigma === 0 || 
			(this.lowSigma === 0 && this.highSigma === 0 &&
				this.lowAlpha === 1.0 && this.highAlpha === 1.0 &&
				this.lowHue === 0 && this.highHue === 0
				)) {
			this.needsSwap = false;
			return;
		}
		this.needsSwap = true;
		
		// Render quad with blured scene into texture (convolution pass 1)

		this.fsQuad.material = this.materialGaussianBlur;

		this.gaussianBlurUniforms[ "tDiffuse" ].value = readBuffer.texture;
		this.gaussianBlurUniforms[ "vertical" ].value = 0.0;
		this.gaussianBlurUniforms[ "sigma"].value = this.cutoffSigma / this.resolution;
		this.gaussianBlurUniforms[ "tGauss"].value = this.cutoffKernel;
		this.gaussianBlurUniforms[ "kernelLookups"].value = this.cutoffLookups;//Math.ceil(Math.max(this.cutoffSigma * 6.0, 3));

		renderer.setRenderTarget( this.renderTargetX );
		renderer.clear();
		this.fsQuad.render( renderer );


		// Render quad with blured scene into texture (convolution pass 2)

		this.gaussianBlurUniforms[ "tDiffuse" ].value = this.renderTargetX.texture;
		this.gaussianBlurUniforms[ "vertical" ].value = 1.0;

		renderer.setRenderTarget( this.renderTargetY );
		renderer.clear();
		this.fsQuad.render( renderer );

		// Subtract low from orig image to get high

		this.fsQuad.material = this.materialCompose;

		this.composeUniforms[ "tDiffuse1" ].value = readBuffer.texture;
		this.composeUniforms[ "tDiffuse2" ].value = this.renderTargetY.texture;
		this.composeUniforms[ "alpha1" ].value = 1.0;
		this.composeUniforms[ "alpha2" ].value = -1.0;
		this.composeUniforms[ "hue1" ].value = 0;
		this.composeUniforms[ "hue2" ].value = 0;

		renderer.setRenderTarget( this.renderTargetHigh );
		renderer.clear();
		this.fsQuad.render( renderer );

		if(this.lowSigma > 0 || this.lowAlpha < 1.0 || this.lowHue !== 0.0) {
			// Render quad with blured low into texture (convolution pass 1)

			this.fsQuad.material = this.materialGaussianBlur;

			this.gaussianBlurUniforms[ "tDiffuse" ].value = this.renderTargetY.texture;
			this.gaussianBlurUniforms[ "vertical" ].value = 0.0;
			this.gaussianBlurUniforms[ "sigma"].value = this.lowSigma / this.resolution;
			this.gaussianBlurUniforms[ "tGauss"].value = this.lowKernel;
			this.gaussianBlurUniforms[ "kernelLookups"].value = this.lowLookups;//Math.ceil(Math.max(this.lowSigma * 6.0, 3));

			renderer.setRenderTarget( this.renderTargetX );
			renderer.clear();
			this.fsQuad.render( renderer );


			// Render quad with blured low into texture (convolution pass 2)

			this.gaussianBlurUniforms[ "tDiffuse" ].value = this.renderTargetX.texture;
			this.gaussianBlurUniforms[ "vertical" ].value = 1.0;

			renderer.setRenderTarget( this.renderTargetY );
			renderer.clear();
			this.fsQuad.render( renderer );
		}

		if(this.highSigma > 0 || this.highAlpha < 1.0 || this.highHue !== 0.0) {
			// Render quad with blured high into texture (convolution pass 1)

			this.fsQuad.material = this.materialGaussianBlur;

			this.gaussianBlurUniforms[ "tDiffuse" ].value = this.renderTargetHigh.texture;
			this.gaussianBlurUniforms[ "vertical" ].value = 0.0;
			this.gaussianBlurUniforms[ "sigma"].value = this.highSigma / this.resolution;
			this.gaussianBlurUniforms[ "tGauss"].value = this.highKernel;
			this.gaussianBlurUniforms[ "kernelLookups"].value = this.highLookups;//Math.ceil(Math.max(this.highSigma * 6.0, 3));

			renderer.setRenderTarget( this.renderTargetX );
			renderer.clear();
			this.fsQuad.render( renderer );


			// Render quad with blured high into texture (convolution pass 2)

			this.gaussianBlurUniforms[ "tDiffuse" ].value = this.renderTargetX.texture;
			this.gaussianBlurUniforms[ "vertical" ].value = 1.0;

			renderer.setRenderTarget( this.renderTargetHigh );
			renderer.clear();
			this.fsQuad.render( renderer );
		}

		// Render original scene with superimposed blur to texture

		this.fsQuad.material = this.materialCompose;

		this.composeUniforms[ "tDiffuse1" ].value = this.renderTargetY.texture;
		this.composeUniforms[ "tDiffuse2" ].value = this.renderTargetHigh.texture;
		this.composeUniforms[ "alpha1" ].value = this.lowAlpha;
		this.composeUniforms[ "alpha2" ].value = this.highAlpha;
		this.composeUniforms[ "hue1" ].value = this.lowHue;
		this.composeUniforms[ "hue2" ].value = this.highHue;

		renderer.setRenderTarget( writeBuffer );
		if ( this.clear ) renderer.clear( renderer.autoClearColor, renderer.autoClearDepth, renderer.autoClearStencil );
		this.fsQuad.render( renderer );

	}

} );

FrequencyDecompPass.blurX = new Vector2( 0.001953125, 0.0 );
FrequencyDecompPass.blurY = new Vector2( 0.0, 0.001953125 );

export { FrequencyDecompPass };