import React from 'react'
import {
    FormControl, MenuItem,
    Select, InputLabel
} from '@material-ui/core'

export function getSelect(title, items, selectedItem, onChange) {
    return (
        <FormControl>
            <InputLabel >{title}</InputLabel>
            <Select
                value={selectedItem}
                onChange={onChange}
            >
                {
                    items.map((el, i) => {
                        return <MenuItem value={el}>{el}</MenuItem>
                    })
                }
            </Select>
        </FormControl>)
}