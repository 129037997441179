import React, { useState, useRef, useMemo } from 'react';
import { Matrix4, Vector3, Euler } from 'three';
import { SceneCanvas } from './SceneComponents/SceneRenderer'
import { CustomSlider } from './ParamControls/CustomSlider'
import {
    Button, TextField, Checkbox, FormControl, Select, InputLabel,
    MenuItem, FormControlLabel, Collapse, ListItem, ListItemText,
    Tooltip
} from '@material-ui/core'
import { ExpandLess, ExpandMore, Menu, SettingsBackupRestore } from '@material-ui/icons'
import * as config from '../config'
import { getReceptiveFieldRect, getRotMats } from './SceneViewUtil'
import { frame } from '@tensorflow/tfjs';
import { PGDAttack } from '../Model/Attacks'
import { IterativeProcessingOverlay } from './SceneComponents/IterativeProcessingOverlay'
import { getRenderParams, tuningCurveParams } from './SceneComponents/AnimationUtils'
import { defaultFragCode } from './SceneComponents/Shaders/PostCustomShader'
import { GradCamView } from './ActivationComponents/GradCamView'
import { getNeuronThumbURL } from '../Model/ModelMetaInfo'
import { objectPairs } from './SceneComponents/ModelPair'
import { backgrounds } from './SceneComponents/TextureBillboard'
import { CustomObjDialog, CustomTextureDialog } from './SceneComponents/LoadingDialogs'
import {
    ttInteractiveScene, ttSceneViewOptions, ttSceneParams,
    ttAdversarialAttackParams, ttCatnessParams, ttPPParams, ttFreqDecompParams,
    ttCameraParams, ttAAModel, ttAANorm, ttAATarget, ttAAAttackAlpha, ttAAOriginalAlpha,
    ttAAPGDButton, ttAAMagnitude,
    ttSCBGBlur, ttSCBGSaturation, ttSCTextureInfl, ttSCTextureBlur, ttSCLightingInfl,
    ttSCFeatVis, ttSCFVToShow,
    ttPPAlpha, ttPPHue, ttPPSaturation, ttPPPatchShuffling, ttPPCustomParam
} from '../strings'



export function SceneView(props) {
    const { modelManager, sceneWidth, receptiveField, animation, update, setNeedsUpdate, enableGradCam,
        showHelp } = props;
    const width = sceneWidth;

    //const loadState = modelManager.useStore(state => state.loaded);
    const defaultState = {
        backgroundTexture: 0,
        objectPair: 0,

        backgroundBlur: 0,
        backgroundSaturation: 1,
        textureInfluence: 1,
        textureBlur: 0,
        lightingInfluence: 1,
        showFV: false,
        featureVisToShow: "mixed4a:222",

        overallCatness: 0,
        shapeCatness: 0.0,
        textureCatness: 0.0,

        alpha: 1,
        hue: 0,
        saturation: 1,
        patchShufflingK: 1,
        customParam: 0.5,
        customGLSL: defaultFragCode,

        cutoffSigma: 0,
        lowSigma: 0,
        highSigma: 0,
        lowAlpha: 1,
        highAlpha: 1,
        lowHue: 0,
        highHue: 0,

        roll: 0,
        pitch: 0,
        yaw: 0
    }

    const [scBackgroundBlur, setSCBackgroundBlur] = useState(defaultState.backgroundBlur);
    const [scBackgroundSaturation, setSCBackgroundSaturation] = useState(defaultState.backgroundSaturation);
    const [scTextureInfluence, setSCTextureInfluence] = useState(defaultState.textureInfluence);
    const [scTextureBlur, setSCTextureBlur] = useState(defaultState.textureBlur);
    const [scLightingInfluence, setSCLightingInfluence] = useState(defaultState.lightingInfluence);

    const setDefaultSceneParams = () => {
        uniformChanged(uniforms, {
            lightingInfluence: defaultState.lightingInfluence,
            lod: defaultState.textureBlur,
            textureInfluence: defaultState.textureInfluence,
        });
        uniformChanged(bgUniforms, {
            lod: defaultState.backgroundBlur,
            saturation: defaultState.backgroundSaturation,
        });

        setSCBackgroundBlur(defaultState.backgroundBlur);
        setSCBackgroundSaturation(defaultState.backgroundSaturation);
        setSCTextureInfluence(defaultState.textureInfluence);
        setSCTextureBlur(defaultState.textureBlur);
        setSCLightingInfluence(defaultState.lightingInfluence);
        setShowFV(defaultState.showFV);
        setFVToShow(defaultState.featureVisToShow);
    }

    const [ppAlpha, setPPAlpha] = useState(defaultState.alpha);
    const [ppHue, setPPHue] = useState(defaultState.hue);
    const [ppSaturation, setPPSaturation] = useState(defaultState.saturation);
    const [ppPatchShufflingK, setPPPatchShufflingK] = useState(defaultState.patchShufflingK);
    const [ppCustomParam, setPPCustomParam] = useState(defaultState.customParam);
    const [ppCustomGLSL, setPPCustomGLSL] = useState(defaultState.customGLSL);

    const defaultEffectParams = {
        alpha: 1.0, hue: 0, saturation: 1.0, customParam: 0.5, fragCode: defaultFragCode,
        cutoffSigma: 0, lowSigma: 0, highSigma: 0, lowAlpha: 1.0, highAlpha: 1.0, lowHue: 0.0,
        highHue: 0.0, patchShufflingK: 1
    };

    const [effectParams, setEffectParams] = useState(defaultEffectParams);

    const setDefaultPPParams = () => {
        const newEffectParams = { ...effectParams };
        newEffectParams.alpha = defaultState.alpha;
        newEffectParams.hue = defaultState.hue;
        newEffectParams.saturation = defaultState.saturation
        newEffectParams.patchShufflingK = defaultState.patchShufflingK;
        newEffectParams.customParam = defaultState.customParam;
        newEffectParams.fragCode = defaultState.customGLSL
        setEffectParams(newEffectParams);

        setPPAlpha(defaultState.alpha);
        setPPHue(defaultState.hue);
        setPPSaturation(defaultState.saturation);
        setPPPatchShufflingK(defaultState.patchShufflingK);
        setPPCustomParam(defaultState.customParam);
        setPPCustomGLSL(defaultState.customGLSL);
    }

    const [fdCutoffSigma, setFDCutoffSigma] = useState(defaultState.cutoffSigma);
    const [fdLowSigma, setFDLowSigma] = useState(defaultState.lowSigma);
    const [fdHighSigma, setFDHighSigma] = useState(defaultState.highSigma);
    const [fdLowHue, setFDLowHue] = useState(defaultState.lowHue);
    const [fdLowAlpha, setFDLowAlpha] = useState(defaultState.lowAlpha);
    const [fdHighHue, setFDHighHue] = useState(defaultState.highHue);
    const [fdHighAlpha, setFDHighAlpha] = useState(defaultState.highAlpha);

    const setDefaultFDParams = () => {
        const newEffectParams = { ...effectParams };
        newEffectParams.cutoffSigma = defaultState.cutoffSigma;
        newEffectParams.lowSigma = defaultState.lowSigma;
        newEffectParams.highSigma = defaultState.highSigma;
        newEffectParams.lowHue = defaultState.lowHue;
        newEffectParams.highHue = defaultState.highHue;
        newEffectParams.lowAlpha = defaultState.lowAlpha;
        newEffectParams.highAlpha = defaultState.highAlpha;


        setEffectParams(newEffectParams);

        setFDCutoffSigma(defaultState.cutoffSigma);
        setFDLowSigma(defaultState.lowSigma);
        setFDHighSigma(defaultState.highSigma);
        setFDLowHue(defaultState.lowHue);
        setFDHighHue(defaultState.highHue);
        setFDLowAlpha(defaultState.lowAlpha);
        setFDHighAlpha(defaultState.highAlpha);
    }

    const [iterativeProcess, setIterativeProcess] = useState(null);
    const [iterativeProcessAlpha, setIterativeProcessAlpha] = useState(1.0);
    const [origImageAlpha, setOrigImageAlpha] = useState(1.0);
    const [overlayImage, setOverlayImage] = useState(null);
    const [attackModelInd, setAttackModelInd] = useState(0);
    const [lpNorm, setLpNorm] = useState("2");
    const [uniforms, setUniforms] = useState({});
    const [bgUniforms, setBgUniforms] = useState({});
    const [rot, setRot] = useState(new Vector3());
    const [showCustomCode, setShowCustomCode] = useState(false);
    const [showPostprocessing, setShowPostproecessing] = useState(false);
    const [showSpectral, setShowSpectral] = useState(false);
    const [showSceneP, setShowSceneP] = useState(false);
    const [showCatnessP, setShowCatnessP] = useState(false);
    const [shapeCatness, setShapeCatness] = useState(0);
    const [textureCatness, setTextureCatness] = useState(0);
    const [showGradCam, setShowGradCam] = useState(false);
    const [showCameraP, setShowCameraP] = useState(false);
    const [showAdvAttack, setShowAdvAttack] = useState(false);
    const [showOptions, setShowOptions] = useState(false);
    const [objectPair, setObjectPair] = useState(0);
    const [customObj, setCustomObj] = useState(null);
    const [objDialogOpen, setObjDialogOpen] = useState(false);
    const [backgroundTexture, setBackgroundTexture] = useState(0);
    const [customBackgroundTexture, setCustomBackgroundTexture] = useState("");
    const [backgroundDialogOpen, setBackgroundDialogOpen] = useState(false);
    const [showFV, setShowFV] = useState(false);
    const [featureVisToShow, setFVToShow] = useState("mixed4a:222");
    const [targetClass, setTargetClass] = useState(0);

    const [dummyState, setDummyState] = useState({});

    if (iterativeProcess) {
        update.asynchronous = true;
    } else {
        update.asynchronous = false;
    }

    let featureVisPath = "";
    const fvTokens = featureVisToShow.split(":");
    if (fvTokens.length === 2) {
        const [fvLayer, fvNeuron] = fvTokens;
        featureVisPath = getNeuronThumbURL(modelManager.getModel(attackModelInd, 0), fvLayer, parseInt(fvNeuron), true);
    } else {
        featureVisPath = "";
    }

    const applyGuiSceneParams = () => {
        setUniforms({ ...uniforms });
        setBgUniforms({ ...bgUniforms });
        setEffectParams({ ...effectParams });
        setIterativeProcessAlpha(iterativeProcessAlpha);
        setOrigImageAlpha(origImageAlpha);
    };

    if (update.setApplyGuiSceneParams) {
        applyGuiSceneParams();
        update.setApplyGuiSceneParams = false;
    }

    const uniformChanged = (unif, changedUniforms) => {
        Object.keys(changedUniforms).forEach((key) => {
            unif[key] = changedUniforms[key];
        });
        setNeedsUpdate(true);
        setDummyState({});
    }

    const { t, l, w, h } = getReceptiveFieldRect(width, receptiveField);

    useMemo(() => {
        const [preRot, postRot] = getRotMats(rot);
        uniformChanged(uniforms, { postRot: postRot, preRot: preRot });
    }, [rot, uniforms]);

    const epsRef = useRef();

    const model = modelManager.getModel(attackModelInd, true);

    const initIterativeProcess = (data, width, height) => {
        const eps = parseFloat(epsRef.current.value);
        const attack = new PGDAttack(
            model.sourceModel,
            { data: data, width: width, height: height });
        const overlayImage =
            attack.doStep(eps, lpNorm, targetClass - 1);
        setOverlayImage(overlayImage);
        setIterativeProcess(attack);
        setNeedsUpdate(true);
    }

    let advAlpha = iterativeProcessAlpha;
    let origAlpha = origImageAlpha;
    if (animation) {
        const renderParams = animation.getRenderParams();
        if ("advAlpha" in renderParams) {
            advAlpha = renderParams.advAlpha;
        }
        if ("advOrigAlpha" in renderParams) {
            origAlpha = renderParams.advOrigAlpha;
        }
    }
    let wrappedOverlayImage = useMemo(() => {
        console.log("wrappedOverlayImage called", advAlpha)
        if ((advAlpha != iterativeProcessAlpha && iterativeProcess) ||
            (origAlpha != origImageAlpha && iterativeProcess)) {
            return iterativeProcess.getCurrent(advAlpha, origAlpha);
        } else {
            return overlayImage;
        }
    }, [advAlpha, origAlpha, overlayImage]);

    let modelNames = [...modelManager.modelDirs];
    modelNames.push("Edited Model");

    const blendName = objectPair < objectPairs.length ? objectPairs[objectPair].blendName : "";

    const textureFileRef = useRef();

    return (
        <div style={{ width: width }}>
            <div className="row" style={{ alignItems: "center" }}>
                <div style={{ flex: "1 1 auto" }}>
                    <Tooltip title={showHelp ? ttInteractiveScene : ""}>
                        <ListItem>
                            <ListItemText primary="Interactive Scene" />
                        </ListItem>
                    </Tooltip>
                </div>
                <div className="row" style={{ flex: "0 1 auto" }}>
                    <Tooltip title={showHelp ? ttSceneViewOptions : ""}>
                        <Button onClick={() => {
                            setShowOptions(!showOptions);
                            setDefaultFDParams();
                        }}>
                            <Menu />
                        </Button>
                    </Tooltip>
                </div>
            </div>
            <Collapse in={showOptions} timeout="auto">
                <div className="row" style={{ padding: 10 }}>
                    <FormControl style={{ width: "100%" }}>
                        <InputLabel id="objectPairLabel">Object Pair</InputLabel>
                        <Select
                            labelId="objectPairLabel"
                            value={objectPair}
                            onChange={(evt) => {
                                setObjectPair(evt.target.value);
                                setNeedsUpdate(true);
                                if (evt.target.value === objectPairs.length) {
                                    setObjDialogOpen(true);
                                } else {
                                    setCustomObj(null);
                                }
                            }}
                        >
                            {
                                objectPairs.map((op, i) => {
                                    return <MenuItem value={i}>{op.name}</MenuItem>
                                })
                            }
                            <MenuItem value={objectPairs.length}>Custom...</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div className="row" style={{ padding: 10 }}>
                    <FormControl style={{ width: "100%" }}>
                        <InputLabel id="backgroundLabel">Background</InputLabel>
                        <Select
                            labelId="backgroundLabel"
                            value={backgroundTexture}
                            onChange={(evt) => {
                                setNeedsUpdate(true);
                                setBackgroundTexture(evt.target.value);
                                if (evt.target.value === backgrounds.length) {
                                    setBackgroundDialogOpen(true);
                                } else {
                                    setCustomBackgroundTexture("");
                                }
                            }}
                        >
                            {
                                backgrounds.map((bg, i) => {
                                    return <MenuItem value={i}>{bg.name}</MenuItem>
                                })
                            }
                            <MenuItem value={backgrounds.length}>
                                Custom...
                                </MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </Collapse>
            <div style={{
                width: width, height: width,
                display: 'grid', gridTemplate: '1fr / 1fr',
                position: 'relative'
            }}>
                <SceneCanvas
                    {...props}
                    setNeedsUpdate={setNeedsUpdate}
                    backgroundTexture={backgroundTexture < backgrounds.length ?
                        backgrounds[backgroundTexture].url : customBackgroundTexture
                    }
                    bgUniforms={bgUniforms}
                    uniforms={uniforms}
                    overlayImage={wrappedOverlayImage}
                    featureVisPath={showFV ? featureVisPath : ""}
                    effectParams={effectParams}
                    objIndex={objectPair}
                    customObj={customObj}
                    initIterativeProcess={iterativeProcess === 'init' ? initIterativeProcess : undefined}
                />
                <IterativeProcessingOverlay model={model} width={width} height={width}
                    overlayImage={wrappedOverlayImage} handleMouseDown={() => {
                        setIterativeProcess(null);
                        setOverlayImage(null);
                        setNeedsUpdate(true);
                    }} />
                {false && <div style={{
                    position: 'absolute',
                    pointerEvents: 'none',
                    top: t,
                    left: l,
                    width: w,
                    height: h,
                    zIndex: 1,
                    border: '2px dotted white',
                    visibility: config.SHOW_RECEPTIVE_FIELD ? 'visible' : 'hidden'
                }}>
                </div>}
            </div>
            <div style={{ width: width }}>
                {enableGradCam && <>
                    <ListItem button onClick={() => {
                        setShowGradCam(!showGradCam);
                    }}>
                        <ListItemText primary="Grad-CAM" />
                        {showSceneP ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={showGradCam} timeout="auto">
                        {showGradCam && <GradCamView
                            width={width}
                            modelManager={modelManager}
                            setNeedsUpdate={setNeedsUpdate} />}
                    </Collapse>
                </>}
                <Tooltip title={showHelp ? ttAdversarialAttackParams : ""}>
                    <ListItem button onClick={() => {
                        setShowAdvAttack(!showAdvAttack);
                    }}>
                        <ListItemText primary="Adversarial Attack" />
                        {showAdvAttack ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                </Tooltip>
                <Collapse className="gray-bg" in={showAdvAttack} timeout="auto">
                    <div style={{ padding: 10 }}>
                        <div className="row" style={{ width: "100%" }}>
                            <Tooltip title={showHelp ? ttAAModel : ""}>
                                <FormControl style={{ width: "100%" }}>
                                    <InputLabel id="attackModelLabel">Model</InputLabel>
                                    <Select
                                        labelId="attackModelLabel"
                                        id="demo-simple-select"
                                        value={attackModelInd}
                                        onChange={(evt) => {
                                            setAttackModelInd(evt.target.value);
                                        }}
                                    >
                                        {
                                            modelNames.map((l, i) => {
                                                return <MenuItem value={i}>{l}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Tooltip>
                        </div>
                        <div className="row">
                            <Tooltip title={showHelp ? ttAANorm : ""}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={lpNorm === "2"}
                                            onChange={(evt, val) => {
                                                setLpNorm(lpNorm === "2" ? "inf" : "2");
                                            }}
                                            value={lpNorm === "2"}
                                        />
                                    }
                                    label={"Using L-" + lpNorm + " norm"}
                                />
                            </Tooltip>
                        </div>
                        <div className="row" style={{ width: "100%" }}>
                            <Tooltip title={showHelp ? ttAATarget : ""}>
                                <FormControl style={{ width: "100%" }}>
                                    <InputLabel id="demo-simple-select-label">Target Class</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={targetClass}
                                        onChange={(evt) => {
                                            setTargetClass(evt.target.value);
                                        }}
                                    >
                                        {(() => {
                                            const menuItems = [<MenuItem value={0}>{"suppress original pred."}</MenuItem>];
                                            for (let i = 0; i < model.getNumClasses(); i++) {
                                                menuItems.push(<MenuItem value={i + 1}>{i + " " + model.getClassName(i)}</MenuItem>);
                                            }
                                            return menuItems;
                                        })()
                                        }
                                    </Select>
                                </FormControl>
                            </Tooltip>
                        </div>
                        <CustomSlider
                            tooltipTitle={showHelp ? ttAAAttackAlpha : ""}
                            labelText="Attack Alpha"
                            defaultValue={1}
                            valueLabelDisplay={"auto"}
                            step={0.01}
                            min={0}
                            max={1.0}
                            onChange={(evt, value) => {
                                setIterativeProcessAlpha(value);
                                if (!iterativeProcess) {
                                    return;
                                }
                                const overlayImage =
                                    iterativeProcess.getCurrent(value, origAlpha);
                                setOverlayImage(overlayImage);
                                setNeedsUpdate(true);
                            }}
                        />
                        <CustomSlider
                            tooltipTitle={showHelp ? ttAAOriginalAlpha : ""}
                            labelText="Orig. Alpha"
                            defaultValue={1}
                            valueLabelDisplay={"auto"}
                            step={0.01}
                            min={0}
                            max={1.0}
                            onChange={(evt, value) => {
                                setOrigImageAlpha(value);
                                if (!iterativeProcess) {
                                    return;
                                }
                                const overlayImage =
                                    iterativeProcess.getCurrent(advAlpha, value);
                                setOverlayImage(overlayImage);
                                setNeedsUpdate(true);
                            }}
                        />
                        <div className="row">
                            <Tooltip title={showHelp ? ttAAPGDButton : ""}>
                                <Button style={{ width: "50%", margin: 5 }} variant="contained" color="primary" onClick={() => {
                                    if (!iterativeProcess) {
                                        setIterativeProcess('init');
                                    } else {
                                        const eps = epsRef.current.value;
                                        const overlayImage =
                                            iterativeProcess.doStep(parseFloat(eps), lpNorm, targetClass - 1);
                                        setOverlayImage(overlayImage);
                                    }
                                    setNeedsUpdate(true);
                                }}
                                >PGD Step</Button>
                            </Tooltip>
                            <Tooltip title={showHelp ? ttAAMagnitude : ""}>
                                <TextField inputRef={epsRef} style={{ width: "50%", margin: 5 }}
                                    defaultValue={100} />
                            </Tooltip>
                        </div>
                    </div>
                </Collapse>
            </div>
            <div style={{ width: width }}>
                <Tooltip title={showHelp ? ttSceneParams : ""}>
                    <ListItem button onClick={() => {
                        setShowSceneP(!showSceneP);
                    }}>
                        <ListItemText primary="Scene" />
                        <Button onClick={(e) => {
                            e.stopPropagation();
                            setDefaultSceneParams();
                        }}><SettingsBackupRestore /></Button>
                        {showSceneP ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                </Tooltip>
                <Collapse className="gray-bg" in={showSceneP} timeout="auto">
                    <div style={{ padding: 10 }}>
                        <CustomSlider
                            tooltipTitle={showHelp ? ttSCBGBlur : ""}
                            labelText="Background blur"
                            value={scBackgroundBlur}
                            valueLabelDisplay={"auto"}
                            step={0.05}
                            min={0}
                            max={10.0}
                            onChange={(evt, value) => {
                                uniformChanged(bgUniforms, { lod: value });
                                setSCBackgroundBlur(value);
                            }}
                        />
                        <CustomSlider
                            tooltipTitle={showHelp ? ttSCBGSaturation : ""}
                            labelText="Background Saturation"
                            value={scBackgroundSaturation}
                            valueLabelDisplay={"auto"}
                            step={0.02}
                            min={0}
                            max={2.0}
                            onChange={(evt, value) => {
                                uniformChanged(bgUniforms, { saturation: value });
                                setSCBackgroundSaturation(value);
                            }}
                        />
                        <CustomSlider
                            tooltipTitle={showHelp ? ttSCTextureInfl : ""}
                            labelText="Texture Influence"
                            value={scTextureInfluence}
                            valueLabelDisplay={"auto"}
                            step={0.02}
                            min={0}
                            max={1}
                            onChange={(evt, value) => {
                                uniformChanged(uniforms, { textureInfluence: value });
                                setSCTextureInfluence(value);
                            }}
                        />
                        <CustomSlider
                            tooltipTitle={showHelp ? ttSCTextureBlur : ""}
                            labelText="Texture Blur"
                            value={scTextureBlur}
                            valueLabelDisplay={"auto"}
                            step={0.05}
                            min={0}
                            max={10.0}
                            onChange={(evt, value) => {
                                uniformChanged(uniforms, { lod: value });
                                setSCTextureBlur(value);
                            }}
                        />
                        <CustomSlider
                            tooltipTitle={showHelp ? ttSCLightingInfl : ""}
                            labelText="Lighting Influence"
                            value={scLightingInfluence}
                            valueLabelDisplay={"auto"}
                            step={0.02}
                            min={0}
                            max={1}
                            onChange={(evt, value) => {
                                uniformChanged(uniforms, { lightingInfluence: value });
                                setSCLightingInfluence(value);
                            }}
                        />
                        <div className="row">
                            <Tooltip title={showHelp ? ttSCFeatVis : ""}>
                                <FormControlLabel style={{ width: "50%", margin: 5 }}
                                    control={
                                        <Checkbox
                                            checked={showFV}
                                            onChange={(evt, val) => {
                                                setShowFV(val);
                                                setNeedsUpdate(true);
                                            }}
                                            value={showFV}
                                        />
                                    }
                                    label={"Feat. Vis."}
                                />
                            </Tooltip>
                            <Tooltip title={showHelp ? ttSCFVToShow : ""}>
                                <TextField style={{ width: "50%", margin: 5 }}
                                    value={featureVisToShow} onChange={(evt, val) => {
                                        setFVToShow(evt.target.value);
                                        setNeedsUpdate(true);
                                    }} />
                            </Tooltip>
                        </div>
                    </div>
                </Collapse>
                <Collapse in={objectPair < objectPairs.length} timeout="auto">
                    <Tooltip title={showHelp ? ttCatnessParams : ""}>
                        <ListItem button onClick={() => {
                            setShowCatnessP(!showCatnessP);
                        }}>
                            <ListItemText primary={blendName} />
                            {showAdvAttack ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                    </Tooltip>
                    <Collapse className="gray-bg" in={showCatnessP} timeout="auto">
                        <div style={{ padding: 10 }}>
                            <CustomSlider
                                labelText={"Overall " + blendName}
                                defaultValue={0}
                                valueLabelDisplay={"auto"}
                                step={0.02}
                                min={0}
                                max={1}
                                onChange={(evt, value) => {
                                    uniformChanged(uniforms, { shapeCatness: value, textureCatness: value });
                                    setShapeCatness(value);
                                    setTextureCatness(value);
                                }}
                            />
                            <CustomSlider
                                labelText={"Shape " + blendName}
                                value={shapeCatness}
                                valueLabelDisplay={"auto"}
                                step={0.02}
                                min={0}
                                max={1}
                                onChange={(evt, value) => {
                                    uniformChanged(uniforms, { shapeCatness: value });
                                    setShapeCatness(value);
                                }}
                            />
                            <CustomSlider
                                labelText={"Texture " + blendName}
                                value={textureCatness}
                                valueLabelDisplay={"auto"}
                                step={0.02}
                                min={0}
                                max={1}
                                onChange={(evt, value) => {
                                    uniformChanged(uniforms, { textureCatness: value });
                                    setTextureCatness(value);
                                }}
                            />
                        </div>
                    </Collapse>
                </Collapse>
                <Tooltip title={showHelp ? ttPPParams : ""}>
                    <ListItem button onClick={() => {
                        setShowPostproecessing(!showPostprocessing);
                    }}>
                        <ListItemText primary="Postprocessing" />
                        <Button onClick={(e) => {
                            e.stopPropagation();
                            setDefaultPPParams();
                        }}><SettingsBackupRestore /></Button>
                        {showPostprocessing ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                </Tooltip>
                <Collapse className="gray-bg" in={showPostprocessing} timeout="auto">
                    <div style={{ padding: 10 }}>
                        <CustomSlider
                            tooltipTitle={showHelp ? ttPPAlpha : ""}
                            labelText="Alpha"
                            value={ppAlpha}
                            valueLabelDisplay={"auto"}
                            step={0.02}
                            min={0}
                            max={1}
                            onChange={(evt, value) => {
                                let newEff = { ...effectParams };
                                newEff.alpha = value;
                                setEffectParams(newEff);
                                let newUnif = { ...uniforms };
                                if ('alpha' in newUnif) {
                                    delete newUnif['alpha'];
                                }
                                setUniforms(newUnif);
                                setPPAlpha(value);
                            }}
                        />
                        <CustomSlider
                            tooltipTitle={showHelp ? ttPPHue : ""}
                            labelText="Hue"
                            value={ppHue}
                            valueLabelDisplay={"auto"}
                            step={0.02}
                            min={-0.5}
                            max={0.5}
                            onChange={(evt, value) => {
                                let newEff = { ...effectParams };
                                newEff.hue = value;
                                setEffectParams(newEff);
                                let newUnif = { ...uniforms };
                                if ('hue' in newUnif) {
                                    delete newUnif['hue'];
                                }
                                setUniforms(newUnif);
                                setPPHue(value);
                            }}
                        />
                        <CustomSlider
                            tooltipTitle={showHelp ? ttPPSaturation : ""}
                            labelText="Saturation"
                            value={ppSaturation}
                            valueLabelDisplay={"auto"}
                            step={0.02}
                            min={0}
                            max={2}
                            onChange={(evt, value) => {
                                let newEff = { ...effectParams };
                                newEff.saturation = value;
                                setEffectParams(newEff);
                                let newUnif = { ...uniforms };
                                if ('saturation' in newUnif) {
                                    delete newUnif['saturation'];
                                }
                                setUniforms(newUnif);
                                setPPSaturation(value);
                            }}
                        />
                        <CustomSlider
                            tooltipTitle={showHelp ? ttPPPatchShuffling : ""}
                            labelText="Patch Shuffling K"
                            value={ppPatchShufflingK}
                            valueLabelDisplay={"auto"}
                            step={1}
                            min={1}
                            max={20}
                            onChange={(evt, value) => {
                                let newEff = { ...effectParams };
                                newEff.patchShufflingK = value;
                                setEffectParams(newEff);
                                let newUnif = { ...uniforms };
                                if ('patchShufflingK' in newUnif) {
                                    delete newUnif['patchShufflingK'];
                                }
                                setUniforms(newUnif);
                                setPPPatchShufflingK(value);
                            }}
                        />
                        <CustomSlider
                            tooltipTitle={showHelp ? ttPPCustomParam : ""}
                            labelText="Custom Parameter"
                            value={ppCustomParam}
                            valueLabelDisplay={"auto"}
                            step={0.02}
                            min={0}
                            max={1}
                            onChange={(evt, value) => {
                                let newEff = { ...effectParams };
                                newEff.customParam = value;
                                setEffectParams(newEff);
                                let newUnif = { ...uniforms };
                                if ('customParam' in newUnif) {
                                    delete newUnif['customParam'];
                                }
                                setUniforms(newUnif);
                                setPPCustomParam(value);
                            }}
                        />
                        <ListItem button onClick={() => {
                            setShowCustomCode(!showCustomCode);
                        }}>
                            <ListItemText primary="Custom GLSL" />
                            {showCustomCode ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse className="gray-bg" in={showCustomCode} timeout="auto">
                            <TextField
                                id="outlined-multiline-flexible"
                                label="Custom GLSL"
                                multiline
                                rowsMax="20"
                                margin="normal"
                                variant="outlined"
                                value={ppCustomGLSL}
                                onChange={(event, value) => {
                                    let newEff = { ...effectParams };
                                    newEff.fragCode = event.target.value;
                                    setEffectParams(newEff);
                                    setPPCustomGLSL(event.target.value);
                                }}
                            />
                        </Collapse>
                    </div>
                </Collapse>
                <Tooltip title={showHelp ? ttFreqDecompParams : ""}>
                    <ListItem button onClick={() => {
                        setShowSpectral(!showSpectral);
                    }}>
                        <ListItemText primary="Frequency Decomp." />
                        <Button onClick={(e) => {
                            e.stopPropagation();
                            setDefaultFDParams();
                        }}><SettingsBackupRestore /></Button>
                        {showSpectral ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                </Tooltip>
                <Collapse className="gray-bg" in={showSpectral} timeout="auto">
                    <div style={{ padding: 10 }}>
                        <CustomSlider
                            labelText="Cutoff Freq."
                            value={fdCutoffSigma}
                            valueLabelDisplay={"auto"}
                            step={.1}
                            min={0}
                            max={30}
                            onChange={(evt, value) => {
                                let newEff = { ...effectParams };
                                newEff.cutoffSigma = value === 0 ? value : 30.1 - value;
                                setEffectParams(newEff);
                                let newUnif = { ...uniforms };
                                if ('cutoffSigma' in newUnif) {
                                    delete newUnif['cutoffSigma'];
                                }
                                setUniforms(newUnif);
                                setFDCutoffSigma(value);
                            }}
                        />
                        <CustomSlider
                            labelText="Low Alpha"
                            value={fdLowAlpha}
                            valueLabelDisplay={"auto"}
                            step={.01}
                            min={0}
                            max={1}
                            onChange={(evt, value) => {
                                let newEff = { ...effectParams };
                                newEff.lowAlpha = value;
                                setEffectParams(newEff);
                                let newUnif = { ...uniforms };
                                if ('lowAlpha' in newUnif) {
                                    delete newUnif['lowAlpha'];
                                }
                                setUniforms(newUnif);
                                setFDLowAlpha(value);
                            }}
                        />
                        <CustomSlider
                            labelText="Low Hue"
                            value={fdLowHue}
                            valueLabelDisplay={"auto"}
                            step={.01}
                            min={-0.5}
                            max={0.5}
                            onChange={(evt, value) => {
                                let newEff = { ...effectParams };
                                newEff.lowHue = value;
                                setEffectParams(newEff);
                                let newUnif = { ...uniforms };
                                if ('lowHue' in newUnif) {
                                    delete newUnif['lowHue'];
                                }
                                setUniforms(newUnif);
                                setFDLowHue(value);
                            }}
                        />
                        <CustomSlider
                            labelText="Low Sigma"
                            value={fdLowSigma}
                            valueLabelDisplay={"auto"}
                            step={.1}
                            min={0}
                            max={40}
                            onChange={(evt, value) => {
                                let newEff = { ...effectParams };
                                newEff.lowSigma = value;
                                setEffectParams(newEff);
                                let newUnif = { ...uniforms };
                                if ('lowSigma' in newUnif) {
                                    delete newUnif['lowSigma'];
                                }
                                setUniforms(newUnif);
                                setFDLowSigma(value);
                            }}
                        />
                        <CustomSlider
                            labelText="High Alpha"
                            value={fdHighAlpha}
                            valueLabelDisplay={"auto"}
                            step={.01}
                            min={0}
                            max={1}
                            onChange={(evt, value) => {
                                let newEff = { ...effectParams };
                                newEff.highAlpha = value;
                                setEffectParams(newEff);
                                let newUnif = { ...uniforms };
                                if ('highAlpha' in newUnif) {
                                    delete newUnif['highAlpha'];
                                }
                                setUniforms(newUnif);
                                setFDHighAlpha(value);
                            }}
                        />
                        <CustomSlider
                            labelText="High Hue"
                            value={fdHighHue}
                            valueLabelDisplay={"auto"}
                            step={.01}
                            min={-0.5}
                            max={0.5}
                            onChange={(evt, value) => {
                                let newEff = { ...effectParams };
                                newEff.highHue = value;
                                setEffectParams(newEff);
                                let newUnif = { ...uniforms };
                                if ('highHue' in newUnif) {
                                    delete newUnif['highHue'];
                                }
                                setUniforms(newUnif);
                                setFDHighHue(value);
                            }}
                        />
                        <CustomSlider
                            labelText="High Sigma"
                            value={fdHighSigma}
                            valueLabelDisplay={"auto"}
                            step={.1}
                            min={0}
                            max={20}
                            onChange={(evt, value) => {
                                let newEff = { ...effectParams };
                                newEff.highSigma = value;
                                setEffectParams(newEff);
                                let newUnif = { ...uniforms };
                                if ('highSigma' in newUnif) {
                                    delete newUnif['highSigma'];
                                }
                                setUniforms(newUnif);
                                setFDHighSigma(value);
                            }}
                        />
                    </div>
                </Collapse>
                <Tooltip title={showHelp ? ttCameraParams : ""}>
                    <ListItem button onClick={() => {
                        setShowCameraP(!showCameraP);
                    }}>
                        <ListItemText primary="Camera" />
                        {showCameraP ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                </Tooltip>
                <Collapse className="gray-bg" in={showCameraP} timeout="auto">
                    <div style={{ padding: 10 }}>
                        <CustomSlider
                            labelText="Roll"
                            defaultValue={0}
                            valueLabelDisplay={"auto"}
                            step={2}
                            min={-180}
                            max={180}
                            onChange={(evt, value) => {
                                const newRot = new Vector3(rot.x, rot.y, rot.z);
                                newRot.z = value / 180 * Math.PI;
                                setRot(newRot);
                            }}
                        />
                        <CustomSlider
                            labelText="Pitch"
                            defaultValue={0}
                            valueLabelDisplay={"auto"}
                            step={2}
                            min={-180}
                            max={180}
                            onChange={(evt, value) => {
                                const newRot = new Vector3(rot.x, rot.y, rot.z);
                                newRot.x = value / 180 * Math.PI;
                                setRot(newRot);
                            }}
                        />
                        <CustomSlider
                            labelText="Yaw"
                            defaultValue={0}
                            valueLabelDisplay={"auto"}
                            step={2}
                            min={-180}
                            max={180}
                            onChange={(evt, value) => {
                                const newRot = new Vector3(rot.x, rot.y, rot.z);
                                newRot.y = value / 180 * Math.PI;
                                setRot(newRot);
                            }}
                        />
                    </div>
                </Collapse>
            </div>
            <CustomObjDialog open={objDialogOpen} handleClose={(ret) => {
                setObjDialogOpen(false);
                if (ret) {
                    const { objFile, diffTexFile, alphaTexFile } = ret;
                    const customObj = {
                        obj: { objFile: objFile, diffTexFile: diffTexFile, alphaTexFile: alphaTexFile },
                        position: [0.08, -0.08, 0]
                    };
                    setCustomObj(customObj);
                } else {
                    setObjectPair(0);
                }
            }} />
            <CustomTextureDialog open={backgroundDialogOpen} handleClose={(ret) => {
                setBackgroundDialogOpen(false);
                if (ret) {
                    setCustomBackgroundTexture(ret);
                } else {
                    setBackgroundTexture(0);
                }
            }} />
        </div>
    );
}