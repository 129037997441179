import {tf} from '../../TFJS'

function download(content, fileName, contentType) {
    var a = document.createElement("a");
    var file = new Blob([content], {type: contentType});
    a.href = URL.createObjectURL(file);
    a.download = fileName;
    a.click();
}

export class TuningCurveResult {
    constructor(animation, resolution, activationParams, neurons) {
        this.animation = animation;
        this.resolution = resolution;
        this.activationParams = activationParams;
        this.animationParams = {
            "xAxis": animation.xAxis,
        }

        this.neurons = neurons;
        this.inferenceData = {};
    }

    hasFullData() {
        return Object.entries(this.inferenceData).length === this.animation.maxFrames();
    }

    getInferenceDataAsArray() {
        let outArr = [];
        Object.entries(this.inferenceData).forEach(([key, obj]) => {
            outArr.push(obj);
        });
        return outArr;
    }

    putData(frameNumber, inferenceData) {
        this.inferenceData[frameNumber] = inferenceData;
        //Snippet to generate test json
        if(frameNumber == this.animation.maxFrames()-1) {
            //download(JSON.stringify(this.inferenceData), "inferenceData.json","text/json");
        }
    }
}