import {Matrix4} from 'three'

export default {
  uniforms: {
    dataTexture: { type: 't', value: null},
    magScale: {value: 1.0}
  },
  
  vertexShader: [
    'attribute float thirdCoord;',
    'attribute float activation;',
    'varying vec2 vUV;',
    'varying float vW;',
    'varying float vActivation;',

    'void main(void) {',
    '	gl_Position = projectionMatrix * viewMatrix * instanceMatrix * vec4(position, 1.0);',
    '	vUV = uv;',
    '	vW = thirdCoord;',
    '	vActivation = activation;',
    '}'
  ].join('\n'),

  fragmentShader: [
    'precision highp float;',
    'precision highp sampler3D;',
    'uniform sampler3D dataTexture;',
    'uniform float magScale;',
    
    'varying vec2 vUV;',
    'varying float vW;',
    'varying float vActivation;',
    
    'void main(void) {',
    ' vec3 col = vec3(texture(dataTexture, vec3(vUV.x, vUV.y, vW)).rgb);',
    ' if(vActivation < 0.0){',
    '   col = vec3(1.0)-col;',
    ' }',
    ' float activ = abs(vActivation*magScale);',
    ' ',
    ' float alpha = clamp(activ, 0.0, 1.0);',
    '	gl_FragColor = vec4(col*alpha, alpha);',
    '}',
    ].join('\n')
  }