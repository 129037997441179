import {Matrix4} from 'three'

const identity = new Matrix4();

export default {
    uniforms: {
        tDiffuse: { type: 't', value: null},
        tShuffledPatches: { type: 't', value: null},
        k: {value: 4}
    },
  
    vertexShader: [
        'varying vec2 vUV;',
        'void main(void) {',
        '    gl_Position = projectionMatrix * viewMatrix * modelMatrix * vec4(position, 1.0);',
        '    vUV = uv;',
        '}'
    ].join('\n'),
  
    fragmentShader: [
        'precision highp float;',
        'precision highp sampler2D;',
        'uniform sampler2D tDiffuse;',
        'uniform sampler2D tShuffledPatches;',
        'uniform float k;',
        'varying vec2 vUV;',

        'vec2 getPatchOrigin(float p) {',
        '   float row = floor(p/k+0.000001);',
        '   float y = row * 1.0/k;',
        '   float col = p - k*row;',
        '   float x = col * 1.0/k;',
        '   return vec2(x, y);',
        '}',

        'vec2 getPatchUV() {',
        '   return (vUV * k - floor(vUV * k))/k;',
        '}',
        
        'void main(void) {',
        '   float p = texture2D(tShuffledPatches, vUV).r;',
        '   vec2 patchUV = getPatchUV();',
        '   vec2 patchOrigin = getPatchOrigin(p);',
        '   vec3 col = texture2D(tDiffuse, patchUV + patchOrigin).rgb;',
            
        '   gl_FragColor = vec4(col, 1.0);',
        '}'
    ].join('\n')
  }