import React, { useState, useMemo, useEffect, useRef } from 'react';
import './App.css';
import './styles.css'
import * as tf from '@tensorflow/tfjs'
import { handleAnimation, handleTuningCurveData, handleModel } from './AppUtil'
import { SceneView } from './Components/SceneView'
import { modelDirNames } from './Model/ModelMetaInfo'
import { ModelManager } from './Model/ModelManager'
import { ModelView } from './Components/ReducedVersionComponents/ModelView'
import './Components/ReducedVersionComponents/General.css'
import { Fab, Tooltip } from '@material-ui/core'
import { HelpOutline, Help } from '@material-ui/icons'

const sceneCanvasWidth = 300;

function App() {
    const [animation, setAnimation] = useState(null);
    const [receptiveField, setReceptiveField] = useState(undefined);
    const [showHelp, setShowHelp] = useState(false);

    const updateRef = useRef();
    if (!updateRef.current) {
        updateRef.current = { needsUpdate: true, asynchronous: false, applyGuiSceneParams: false };
    }
    const update = updateRef.current;
    const setNeedsUpdate = (u, caller = undefined) => {
        console.log("setneedsupdate  ")
        update.needsUpdate = u;
    };

    const modelManager = useMemo(() => {
        return new ModelManager({ model1: modelDirNames[1], model2: modelDirNames[2] });
    }, []);
    const loadState = modelManager.useStore(state => state.loaded);

    let showLoadingProgress = false;
    Object.entries(loadState).forEach(([key, value]) => {
        if (value === false) {
            showLoadingProgress = true;
        }
    })

    console.log("loadstate", loadState, showLoadingProgress)

    const startTime = useRef();
    const frames = useRef();

    const onNewFrame = (data, width, height) => {
        if(startTime.current === undefined || frames.current % 15 === 0) {
            if(startTime.current) {
                const fps = frames.current / (new Date().getTime() - startTime.current)*1000;
                console.log("last fps was: "+fps);
            }
            startTime.current = new Date().getTime();
            frames.current = 0;
        }

        handleModel(modelManager.getModel(0), sceneCanvasWidth, animation, data, width, height, update.asynchronous);
        if (modelManager.getModel(1).isLoaded()) {
            handleModel(modelManager.getModel(1), sceneCanvasWidth, animation, data, width, height, update.asynchronous);
        }
        if (modelManager.getMixedModel() && modelManager.getMixedModel().isLoaded()) {
            handleModel(modelManager.getMixedModel(), sceneCanvasWidth, animation, data, width, height, update.asynchronous);
        }
        frames.current += 1;
    }

    return (
        <div className="App">
            {<div className="row">
                <div id="layout">
                    <div className="row">
                        <div style={{ width: "10px" }} />
                        <div className="column">
                            <SceneView receptiveField={receptiveField}
                                showHelp={showHelp}
                                modelManager={modelManager}
                                animation={animation}
                                onNewFrame={onNewFrame}
                                sceneWidth={sceneCanvasWidth}
                                update={update}
                                setNeedsUpdate={setNeedsUpdate}
                            />
                        </div>
                        <div style={{ width: "40px" }} />
                        {true && <ModelView
                            modelManager={modelManager}
                            setNeedsUpdate={setNeedsUpdate}
                            sceneCanvasWidth={sceneCanvasWidth}
                            showLoadingProgress={showLoadingProgress}
                            showHelp={showHelp}
                        />}
                    </div>
                </div>
            </div>}
            <div></div>
            <Tooltip title="Toggle Tooltips">
                <Fab
                    color="primary"
                    style={{
                        position: "fixed",
                        right: "10px",
                        bottom: "10px",
                        zIndex: 10
                    }}
                    onClick={() => {
                        setShowHelp(!showHelp)
                    }}>
                    {showHelp ?
                        (<Help />) : (<HelpOutline />)}
                </Fab>
            </Tooltip>
        </div>
    )
}

export default App;