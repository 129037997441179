import React, { useState } from 'react'
import { CustomSlider } from './ParamControls/CustomSlider'
import { inceptionV1InterestingLayers } from '../Model/ModelMetaInfo'
import { ListItem, ListItemText, Button, Collapse } from '@material-ui/core'
import {Menu, MenuOpen} from '@material-ui/icons'
import {getSelect} from './Util/MaterialUtil'


function getLayerSliders(layerMix, onChange) {
    const layerNames = Object.entries(layerMix).map(([name, value]) => {
        return name;
    });
    return layerNames.map((layerName) => {
        return <div className="row">
            <CustomSlider
                labelText={layerName}
                valueLabelDisplay={"auto"}
                step={0.02}
                min={0}
                max={1}
                value={layerMix[layerName]}
                onChange={(evt, value) => {
                    onChange(layerName, value);
                }}
            />
        </div>
    })
}

function getContent(editingMode, modelManager, setNeedsUpdate, setState) {
    if(editingMode === 'Mix')
    {
        return getLayerSliders(modelManager.getMixedModel().layerMix, (layerKey, value) => {
            if (layerKey === "all") {
                Object.entries(modelManager.getMixedModel().layerMix).forEach(([key, val]) => {
                    modelManager.getMixedModel().setLayerMix(key, value);
                });
            } else {
                modelManager.getMixedModel().setLayerMix(layerKey, value);
            }
            setState({});
            setNeedsUpdate(true);
        });
    } else if (editingMode === 'Prune') {
        return getLayerSliders(modelManager.getMixedModel().layerPruning, (layerKey, value) => {
            if (layerKey === "all") {
                Object.entries(modelManager.getMixedModel().layerPruning).forEach(([key, val]) => {
                    modelManager.getMixedModel().setLayerPruningRange(key, value);
                });
            } else {
                modelManager.getMixedModel().setLayerPruningRange(layerKey, value);
            }
            setState({});
            setNeedsUpdate(true);
        });
    }
}

const editingModes = ['Mix', 'Prune'];

export function ModelMixer(props) {
    const { width, modelManager, setNeedsUpdate, showWeightEditing, setShowWeightEditing } = props;

    const [, setState] = useState();
    const [showOptions, setShowOptions] = useState(false);
    const [editingMode, setEditingMode] = useState("Mix");

    const editingModeSelect = getSelect("Editing Mode",
    editingModes, editingMode, (evt) => {
        setEditingMode(evt.target.value);
    });

    return (<div className="column"
        style={{ width: width/*borderRight: "1px solid gray"*/ }}>
        <div className="row" style={{ alignItems: "center" }}>
            <div style={{ flex: "1 1 auto" }}>
                <ListItem button onClick={() => {
                        setShowWeightEditing(!showWeightEditing);
                    }}>
                    <ListItemText primary="Weight Editing" />
                </ListItem>
            </div>
            <div className="row" style={{ flex: "0 1 auto" }}>
                <Button onClick={() => {
                    setShowOptions(!showOptions);
                }}>
                    <Menu />
                </Button>
            </div>
        </div>
        <Collapse in={showOptions} timeout="auto">
            <div className="column" style={{padding:"10px"}}>
                {editingModeSelect}
            </div>
        </Collapse>
        {true && <div className="column mixedModelColor" style={{ padding: 10, borderRadius:"15px" }}>
            {getContent(editingMode, modelManager, setNeedsUpdate, setState)}
        </div>}
    </div>);
}