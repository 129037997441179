import {PNG} from 'pngjs'
import {saveAs} from 'file-saver'
var JSZip = require("jszip");

window.requestFileSystem  = window.requestFileSystem ||
window.webkitRequestFileSystem;

let fileSystem;
let zip = new JSZip();
let img = zip.folder("raw_images");

if(!fileSystem) {
    //initFileSystem(() => console.log("file system initialized"));
}

function errorHandler(e) {
    console.log('Error: ' + e);
}

export function initFileSystem(callback) {
    function onInitFs(fs) {
        fileSystem = fs;
        console.log('FileSystem:', fs.root.toURL());
      }

    window.webkitStorageInfo.requestQuota(window.PERSISTENT, 1024*1024*1024, function(grantedBytes) {
        window.requestFileSystem(window.PERSISTENT, grantedBytes, onInitFs, errorHandler);
      }, function(e) {
        console.log('Error', e);
      });
}

function writePng(imageData, index, raw=true) {
    const numStr = ""+index;
    let nameString;
    if (raw) {
        nameString = "raw_img_"+numStr.padStart(3, '0')+".png";
    } else {
        nameString = "tuning_curves_"+numStr.padStart(2, '0')+".png";
    }
    console.log(nameString);

    const png = new PNG({
        filterType: 4,
        width: imageData.width,
        height: imageData.height
    });
    png.data.set(imageData.data);
    const options = { colorType: 6 };
    const buffer = PNG.sync.write(png, options);

    if(raw){
        img.file(nameString, buffer, {binary: true});
    } else {
        zip.file(nameString, buffer, {binary: true});
    }

    return;
    

    fileSystem.root.getFile(nameString, {create: true}, (fileEntry) => {

        // Create a FileWriter object for our FileEntry (log.txt).
        fileEntry.createWriter((fileWriter) => {
            const png = new PNG({
                filterType: 4,
                width: imageData.width,
                height: imageData.height
            });
            png.data.set(imageData.data);
            const options = { colorType: 6 };
            const buffer = PNG.sync.write(png, options);
            const blob = new Blob([buffer], {type: 'image/png'});
            fileWriter.write(blob);
        });
    });
}

export function resetZip() {
    zip = new JSZip();
    img = zip.folder("raw_images");
}

function writeJson(jsonString, index) {
    const numStr = ""+index;
    const nameString = "activation_data_"+numStr.padStart(2, '0')+".json";
    console.log(nameString)
    zip.file(nameString, jsonString);
}

export function writeData(data, index) {
    if(!fileSystem && false) {
        initFileSystem(() => writeJson(data, index));
    } else {
        writeJson(data, index);
    }
}

export function writeTuningCurve(imageData, index) {
    if(!fileSystem && false) {
        initFileSystem(() => writePng(imageData, index));
    } else {
        writePng(imageData, index, false);
    }
}

export function writeImage(imageData, index) {
    if(!fileSystem && false) {
        initFileSystem(() => writePng(imageData, index));
    } else {
        writePng(imageData, index);
    }
}

export function downloadZip() {
    zip.generateAsync({type:"blob"}).then(content => {
        saveAs(content, "data.zip");
    })
}