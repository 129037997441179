import React, { useState, useMemo } from 'react'
import { getSelect } from '../Util/MaterialUtil'
import { inceptionV1InterestingLayers, modelTypes, getModelIterations } from '../../Model/ModelMetaInfo'
import { CustomSlider } from '../ParamControls/CustomSlider'
import { getInterestiingNeurons } from './InterestingNeurons'
import { CircularProgress, Tooltip } from '@material-ui/core'
import {
    ttLayerSelect, ttNeuronSelect, ttSelectIteration,
    ttSelectModel1, ttSelectModel2
} from '../../strings'

export function ModelControlView(props) {
    const { model, setNeedsUpdate, modelTrainTypes,
        setModelTrainTypes,
        modelIterations,
        setModelIterations,
        targetLayer,
        setTargetLayer,
        neuronGroup,
        setNeuronGroup,
        showLoadingProgress,
        setDragging,
        showHelp
    } = props;
    const modelTypeItems = Object.entries(modelTypes).map(([key, type]) => {
        return type;
    });

    const interestingNeurons = getInterestiingNeurons(targetLayer);

    const interestingNeuronSelect = getSelect("Interesting Neurons",
        interestingNeurons.names, neuronGroup, (evt) => {
            setNeuronGroup(evt.target.value);
            setNeedsUpdate(true);
        });

    const model1Iterations = getModelIterations(modelTrainTypes[0]);
    const model2Iterations = getModelIterations(modelTrainTypes[1]);

    console.log("modelcontrolview loadingprogress", showLoadingProgress)

    return <div className="column" style={{ width: "100%", position: "relative" }}>
        <div>
            <div className="row" style={{ width: "100%" }}>
                <Tooltip title={showHelp ? ttLayerSelect : ""}>
                    <div className="column" style={{ flex: "1 1 50%", padding: "10px" }}>
                        {getSelect("Layer", inceptionV1InterestingLayers, targetLayer, (evt) => {
                            setTargetLayer(evt.target.value);
                            setNeuronGroup(null);
                            setNeedsUpdate(true);
                        })}
                    </div>
                </Tooltip>
                <Tooltip title={showHelp ? ttNeuronSelect : ""}>
                    <div className="column" style={{ flex: "1 1 50%", padding: "10px" }}>{interestingNeuronSelect}</div>
                </Tooltip>
            </div>
            <div className="row" style={{ width: "100%" }}>
                <div className="column model1Color padding" style={{ flex: "1 1 50%", padding: "10px", borderRadius: "10px" }}>
                    <Tooltip title={showHelp ? ttSelectModel1 : ""}>
                        {getSelect("Model 1", modelTypeItems, modelTrainTypes[0], (evt) => {
                            setModelTrainTypes((currModelTypes) => {
                                const newState = [...currModelTypes];
                                newState[0] = evt.target.value;;
                                if (newState[0] === modelTypes.STYL) {
                                    setModelIterations((modelIts) => {
                                        const newModelIts = [...modelIts];
                                        newModelIts[0] = Math.min(7, modelIts[0]);
                                        return newModelIts;
                                    });
                                }
                                return newState;
                            });
                            setNeedsUpdate(true);
                        })}
                    </Tooltip>
                    {(modelTrainTypes[0] !== modelTypes.STD) &&
                        <CustomSlider
                            tooltipTitle={showHelp ? ttSelectIteration : ""}
                            labelText={"Iteration " + model1Iterations[modelIterations[0]]}
                            valueLabelDisplay={"auto"}
                            step={1}
                            min={0}
                            max={model1Iterations.length - 1}
                            value={modelIterations[0]}
                            onChange={(evt, value) => {
                                setDragging(true);
                                setModelIterations((modelIts) => {
                                    const newState = [...modelIts];
                                    newState[0] = value;
                                    return newState;
                                });
                            }}
                            onChangeCommitted={(evt, value) => {
                                setDragging(false);
                            }}
                        />
                    }
                </div>
                <div className="column model2Color padding" style={{ flex: "1 1 50%", padding: "10px", borderRadius: "10px" }}>
                    <Tooltip title={showHelp ? ttSelectModel2 : ""}>
                        {getSelect("Model 2", modelTypeItems, modelTrainTypes[1], (evt) => {
                            setModelTrainTypes((currModelTypes) => {
                                const newState = [...currModelTypes];
                                newState[1] = evt.target.value;
                                if (newState[1] === modelTypes.STYL) {
                                    setModelIterations((modelIts) => {
                                        const newModelIts = [...modelIts];
                                        newModelIts[1] = Math.min(7, modelIts[1]);
                                        return newModelIts;
                                    });
                                }
                                return newState;
                            });
                            setNeedsUpdate(true);
                        })}
                    </Tooltip>
                    {(modelTrainTypes[1] !== modelTypes.STD) &&
                        <CustomSlider
                            tooltipTitle={showHelp ? ttSelectIteration : ""}
                            labelText={"Iteration " + model2Iterations[modelIterations[1]]}
                            valueLabelDisplay={"auto"}
                            step={1}
                            min={0}
                            max={model2Iterations.length - 1}
                            value={modelIterations[1]}
                            onChange={(evt, value) => {
                                setDragging(true);
                                setModelIterations((modelIts) => {
                                    const newState = [...modelIts];
                                    newState[1] = value;
                                    return newState;
                                });
                            }}
                            onChangeCommitted={(evt, value) => {
                                setDragging(false);
                            }}
                        />
                    }
                </div>
            </div>
        </div>
        <div className="row" style={{
            pointerEvents: "none",
            top: 0, left: 0, position: "absolute", zIndex: 10,
            width: "100%", height: "100%", alignItems: "center", justifyContent: "center"
        }} >{showLoadingProgress && <CircularProgress />}</div>
    </div>
}