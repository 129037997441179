import React, { useRef, useMemo, useState } from 'react'
import { Math as THREEMath, MeshStandardMaterial, ShaderMaterial, DoubleSide, TextureLoader } from 'three'
import { useFrame, useLoader, object3D, boxGeometry, meshStandardMaterial } from 'react-three-fiber'
import {loadDefaultModel, loadCustomModel, setApplicablePropUniforms,
    defaultDiffuseURL, catDiffuseURL, defaultMaskURL} from './ObjectUtil'
import {ModelPair, DogModel, objectPairs} from './ModelPair'


export function ObjectMesh(props) {
    const {uniforms, diffusePath, alphaPath, customObj, setNeedsUpdate, objIndex} = props;
    const [obj, setObj] = useState(null);
    const [material, setMaterial] = useState(null);
    const [position, setPosition] = useState([.02,-0.6,-0.5]);
    const [scale, setScale] = useState([1,1,1]);
    const diffuseTexture1Ref = useRef();
    const diffuseTexture2Ref = useRef();
    const alphaTextureRef = useRef();
    const objPairData = objIndex === objectPairs.length ? "custom" : objectPairs[objIndex];

    useMemo(() => {
        if (objPairData === "custom") return;
        new TextureLoader().load(objPairData.diffPath1, (texture) => {
            diffuseTexture1Ref.current = texture;
            texture.flipY = false;
        });
        new TextureLoader().load(objPairData.diffPath2, (texture) => {
            diffuseTexture2Ref.current = texture;
            texture.flipY = false;
        });
        new TextureLoader().load(objPairData.maskPath, (texture) => {
            alphaTextureRef.current = texture;
            texture.flipY = false;
        });
    }, [objPairData]);

    const objPair = useMemo(() => {
        if (objPairData === "custom") {
            const callback = (loadedObj, loadedMat) => {
                setObj(loadedObj);
                setMaterial(loadedMat);
                setNeedsUpdate(true);
            }
            if(customObj) {
                loadCustomModel(customObj.obj, callback);
                setPosition(customObj.position);
            } else {
                //loadDefaultModel(callback);
            }
        } else {
            const objPair = new ModelPair();
            objPair.load(objPairData.objPath1, objPairData.objPath2, (loadedObj, loadedMat) => {
                setObj(loadedObj);
                setMaterial(loadedMat);
                setNeedsUpdate(true);
                if(objPairData.name.includes("Fire")) {
                    setPosition([.002,-0.06,-0.05]);
                    setScale([1,1,1]);
                } else {
                    setPosition([.02,-0.6,-0.5]);
                    setScale([1,1,1]);
                }
            });
        }
    }, [objPairData, customObj]);

    const mesh = useRef();
    const dummy = useRef();

    useFrame(() => {
        if(!mesh.current) {
            console.log("no model in objectmesh")
            return;
        } else {
            mesh.current.children[0].geometry.attributes.position.needsUpdate = true
        }
    });

    if(obj){
        if(material) {
            if(!customObj) {
                material.uniforms.colorMap.value = diffuseTexture1Ref.current;
                material.uniforms.secondColorMap.value = diffuseTexture2Ref.current;
                material.uniforms.alphaMap.value = alphaTextureRef.current;
            }
            if(!material.uniforms.alphaMap.value) {
                    material.transparent = false;
            }
        }
        setApplicablePropUniforms(material, uniforms);
        return <primitive object={obj} ref={mesh} position={position} scale={scale}/>
    } else {
        return <mesh
        ref={dummy}>
        <boxBufferGeometry attach="geometry" args={[.1, .1, .1]} />
        <meshStandardMaterial attach="material"/>
      </mesh>
    }
}