import React, { useRef, useEffect, useMemo } from 'react'
import { extend, useThree, useFrame } from 'react-three-fiber'
import {DataTexture, UnsignedByteType, RGBAFormat} from 'three'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer'
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass'
//import { BloomPass } from 'three/examples/jsm/postprocessing/BloomPass'
import {FrequencyDecompPass} from './FrequencyDecompPass'
import { AlphaShader,  OverlayShader, SaturationShader, getCustomShader, PatchShuffleShader} from '../Shaders'
import {getEffectPasses, getGaussianKernelTexture, getRandomShuffledPatchTexture} from './EffectsUtil'
import GaussianBlurShader from './Shaders/GaussianBlurShader'
import { FormatSizeTwoTone } from '@material-ui/icons'

extend({ EffectComposer, ShaderPass, RenderPass, FrequencyDecompPass })

const getShaderPass = (shader, key, refArray) => {
    return(<shaderPass ref={passRef => refArray[key] = passRef} attachArray="passes" args={[shader]} />);
}

export function Effects(props) {
    const {effectParams, overlayImageData} = props;
    const composer = useRef();
    const decompPass = useRef();
    const overlayPass = useRef();
    const patchShufflePass = useRef();
    const gaussHorizontalPass = useRef();
    const gaussVerticalPass = useRef();
    const { scene, gl, size, camera } = useThree()
    useEffect(() => void composer.current.setSize(size.width, size.height), [size]);

    const cutoffSigma = effectParams.cutoffSigma;
    const lowSigma = effectParams.lowSigma;
    const highSigma = effectParams.highSigma;
    const lowAlpha = effectParams.lowAlpha;
    const highAlpha = effectParams.highAlpha;
    const lowHue = effectParams.lowHue;
    const highHue = effectParams.highHue;
    const psK = effectParams.patchShufflingK;

    const cutoffKernelTexture = useMemo(() => 
    getGaussianKernelTexture(cutoffSigma), [cutoffSigma]);

    const lowKernelTexture = useMemo(() => 
    getGaussianKernelTexture(Math.max(lowSigma, 0.0001)), [lowSigma]);

    const highKernelTexture = useMemo(() => 
    getGaussianKernelTexture(Math.max(highSigma, 0.0001)), [highSigma]);

    const gaussianKernelTexture = useMemo(() => 
    getGaussianKernelTexture(effectParams.sigma), [effectParams.sigma]);

    const shuffledPatchTexture = useMemo(() =>
    getRandomShuffledPatchTexture(psK), [psK]);

    let overlayTexture = useMemo(() => {
        if(overlayImageData) {
            const t = new DataTexture(
                overlayImageData.data, overlayImageData.w, overlayImageData.h,
                RGBAFormat, UnsignedByteType);
            t.needsUpdate=true;
            t.flipY = true;
            return t;
        } else {
            return null;
        }
    }, [overlayImageData]);

    const effectPassInfo = useMemo(() => {
        return getEffectPasses(effectParams.fragCode);
    }, [effectParams.fragCode]);

    const shaderPassRefs = useMemo(() => [], [effectPassInfo]);

    const shaderPasses = useMemo(() => {
        return effectPassInfo.map(({name, shader}, i) => {
            return getShaderPass(shader, i , shaderPassRefs);
        });
    }, [effectPassInfo]);

    shaderPassRefs.forEach((shaderPassRef, i) => {
        const name = effectPassInfo[i].name;
        shaderPassRef.uniforms[name].value = effectParams[name];
    });

    useFrame(() => {
        if(overlayPass.current) {
            overlayPass.current.uniforms.tOverlay.value = overlayTexture;
            if(overlayImageData) {
                overlayPass.current.uniforms.alpha.value = 1.0;
            } else {
                overlayPass.current.uniforms.alpha.value = 0.0;
            }
        }
        if(gaussHorizontalPass.current) {
            gaussHorizontalPass.current.enabled = effectParams.sigma ? true : false;
            gaussHorizontalPass.current.uniforms.tGauss.value = gaussianKernelTexture;
            gaussHorizontalPass.current.uniforms.sigma.value = effectParams.sigma / size.width;
            gaussHorizontalPass.current.uniforms.kernelLookups.value = Math.ceil(Math.max(effectParams.sigma * 6.0, 3));
        }
        if(gaussVerticalPass.current) {
            gaussVerticalPass.current.enabled = effectParams.sigma ? true : false;
            gaussVerticalPass.current.uniforms.tGauss.value = gaussianKernelTexture;
            gaussVerticalPass.current.uniforms.sigma.value = effectParams.sigma / size.width;
            gaussVerticalPass.current.uniforms.kernelLookups.value = Math.ceil(Math.max(effectParams.sigma * 6.0, 3));
            gaussVerticalPass.current.uniforms.vertical.value = 1.0;
        }
        if(decompPass.current) {
            decompPass.current.cutoffSigma= cutoffSigma ? cutoffSigma : 0;
            decompPass.current.lowSigma = lowSigma;
            decompPass.current.highSigma = highSigma;
            decompPass.current.cutoffKernel = cutoffKernelTexture;
            decompPass.current.lowKernel = lowKernelTexture;
            decompPass.current.highKernel = highKernelTexture;
            decompPass.current.lowAlpha = lowAlpha;
            decompPass.current.highAlpha = highAlpha;
            decompPass.current.lowHue = lowHue;
            decompPass.current.highHue = highHue;
        }
        if(patchShufflePass.current) {
            patchShufflePass.current.uniforms.tShuffledPatches.value = shuffledPatchTexture;
            patchShufflePass.current.uniforms.k.value = psK;
        }
        composer.current.render()
    }, 2);
    return (
        <effectComposer ref={composer} args={[gl]}>
            <renderPass attachArray="passes" scene={scene} camera={camera} />
            {shaderPasses}
            {/*<shaderPass ref={gaussHorizontalPass} attachArray="passes" args={[GaussianBlurShader]}/>}
            {<shaderPass ref={gaussVerticalPass} attachArray="passes" args={[GaussianBlurShader]}/>*/}
            {<frequencyDecompPass ref={decompPass} attachArray="passes" args={[300]}/>}
            {<shaderPass ref={patchShufflePass} attachArray="passes" args={[PatchShuffleShader]} />}
            {<shaderPass ref={overlayPass} attachArray="passes" args={[OverlayShader]} />}

        </effectComposer>
    )
}
