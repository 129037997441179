import React, { useRef, useEffect, useState, useMemo } from 'react'
import ReactDOM from 'react-dom'
import { Canvas, useFrame, useThree } from 'react-three-fiber'
import { DoubleSide, DataTexture, LuminanceFormat, FloatType } from 'three'
import {ActivationPlane} from './ActivationPlane'
import {getOutputDefGenerator, getNeuronInferenceCallback } from '../ActivationViewUtil'

function Scene(props) {
    const { width, height, activationParams, activations, index } = props;

    const { gl, scene, camera, size } = useThree()

    camera.left = -0.5;
    camera.right = 0.5;
    camera.top = 0.5;
    camera.bottom = -0.5;
    camera.near = 0.1;
    camera.far = 1000;
    camera.updateProjectionMatrix();

    return (
        <React.Suspense fallback={<mesh />}>
            <ActivationPlane w={1} x={0}
                params={activationParams} activation={activations}/>
        </React.Suspense>);
}

export function ActivationCanvas(props) {
    const { model, width, height, activationParams, index, visible } = props;

    const activationsRef = useRef({ data: new Float32Array([0.5, -.5, 0.2, 0.3]), shape: { w: 1, h: 1 } });

    if(visible) {
        const generator = getOutputDefGenerator(
            activationParams[index], getNeuronInferenceCallback(activationsRef), activationParams[index].normalize === 0);
        model.outputManager.registerOutputDefGenerators("combinedActivationView"+index, [generator]);
    } else {
        model.outputManager.deregisterOutputDefGenerators("combinedActivationView"+index);
    }

    return (
        <div>
            {visible && <Canvas style={{width: width, height: height, background: "transparent" }} orthographic={true}>
                <Scene width={width} height={height} activationParams={activationParams[index]} activations={activationsRef.current} />
            </Canvas>}
        </div>
    )
}
