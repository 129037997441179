import React, { useRef, useEffect, useMemo } from 'react'
import { extend, useThree, useFrame, useLoader } from 'react-three-fiber'
import {TextureLoader, Vector3, ShaderMaterial} from 'three'
import {BillboardShader} from './Shaders'
import {setApplicablePropUniforms} from './ObjectUtil'

// background texture is public domain image from
// https://www.flickr.com/photos/trianglereva/14733514402/in/photolist-orX7wo-Z94iPi-CScozM-Er1XtD-288U1wq-XV2wgq-28mPQnQ-24pbfuk-2bvoUSc-PXndPY-2cYtQ1w-JBvJaq-S8PwVj-AanKpm-KeaAry-2akvjrr-2aBCu3j-PSZqVs-23f2MmB-28EnbDW-24v8vFD-ScrZQS-KfsnzX-2et83Us-29tdigv-27thd4d-2bbkovv-26mPosi-HiGjGT-28RguXR-MExL9i-2a4K4zB-YZLg5x-2cLoXg6-E7HAKr-22a9gM1-HAkM3K-26bavt2-EpLxhY-27Ppjsr-AgoECo-HxevER-FRGePM-22kfCRq-Em6JUb-bjC7kx-eCRyVo-21HoRFy-23RwbaM-2bdu7Ay
export function FeatureVisBillboard(props) {
    const {uniforms, texturePath} = props;
    const plane = useRef()
    const ref = useRef()
    let rot = 0

    let texture = useLoader(TextureLoader, texturePath);
    
    const shaderMaterial = useMemo(() => new ShaderMaterial({
        uniforms: {...BillboardShader.uniforms},
        vertexShader: BillboardShader.vertexShader,
        fragmentShader: BillboardShader.fragmentShader
    }), []);

    useFrame(() => {
        if(shaderMaterial.uniforms.color.value !== texture) {
            shaderMaterial.uniforms.color.value = texture;
            texture.needsUpdate=true;
        }

        if(ref.current) {
            ref.current.frustumCulled = false;
            ref.current.position.set(0,0,0);
            ref.current.updateMatrix();
            ref.current.material = shaderMaterial;
        }
        setApplicablePropUniforms(shaderMaterial, uniforms);
    });

    return <mesh
    ref={ref}>
    <planeBufferGeometry ref={plane} attach="geometry" args={[20, 20, 1]} />
    </mesh>
}
