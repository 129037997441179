import {ObjectShader} from './Shaders'
import {OBJLoader} from './OBJLoader'
import {ShaderMaterial, DoubleSide, Texture} from 'three'
import {getDefaultMaterial} from './ObjectUtil'
import {Float32BufferAttribute} from 'three'

export const dogDiffuseURL = process.env.PUBLIC_URL + '/assets/dog/Textures/Color.jpg';
export const catDiffuseURL = process.env.PUBLIC_URL + '/assets/cat/Textures/Color.jpg';
export const defaultMaskURL = process.env.PUBLIC_URL + '/assets/dog/Textures/FurMaskBW.jpg';
const dogObjURL = process.env.PUBLIC_URL + "/assets/dog/Shepherd_from_houdini.obj"
const catObjURL = process.env.PUBLIC_URL + "/assets/cat/Cat_from_houdini.obj"

export const objectPairs = [
    {
        name: 'Dog / Cat',
        diffPath1: '/assets/dog/Textures/Color.jpg',
        diffPath2: '/assets/cat/Textures/Color.jpg',
        maskPath: '/assets/dog/Textures/FurMaskBW.jpg',
        objPath1: "/assets/dog/Shepherd_from_houdini.obj",
        objPath2: "/assets/cat/Cat_from_houdini.obj",
        blendName: "Cat-ness"
    },
    {
        name: 'Fire Truck / Race Car',
        diffPath1: '/assets/firetruck/fire_truck.jpg',
        diffPath2: '/assets/racecar/race_car.jpg',
        maskPath: '/assets/firetruck/mask.jpg',
        objPath1: "/assets/firetruck/fire_truck.obj",
        objPath2: "/assets/racecar/race_car.obj",
        blendName: "Race Car-ness"
    },
]

export class ModelPair {
    constructor() {
        this.group1 = null;
        this.group2 = null;
    }

    load(objUrl1, objUrl2, callback) {
        this.group1 = null;
        this.group2 = null;
        const whenBothLoaded = () => {
            if(!this.group1 || !this.group2) {
                return;
            }
            if(this.group1.children.length !== this.group2.children.length) {
                return;
            }
            
            this.group1.children.forEach((mesh1, i) => {
                const mesh2 = this.group2.children[i];
                const positions2 = mesh2.geometry.attributes.position;
                const normals2 = mesh2.geometry.attributes.normal;
                mesh1.geometry.setAttribute( 'position2', new Float32BufferAttribute( positions2.array, 3 ) );
                mesh1.geometry.setAttribute( 'normal2', new Float32BufferAttribute( normals2.array, 3 ) );
            });
            callback(this.group1, material);
        }

        const material = getDefaultMaterial();

        const assignMaterial = (group, material) => {
            group.children.forEach((mesh) => {
                mesh.material = material;
            });
        }

        new OBJLoader().load(objUrl1, group => {
            group.children.forEach((mesh) => {
                mesh.material = material;
            });
            this.group1 = group;
            assignMaterial(this.group1, material);
            whenBothLoaded();
        });

        new OBJLoader().load(objUrl2, group => {
            group.children.forEach((mesh) => {
                mesh.material = material;
            });
            this.group2 = group;
            assignMaterial(this.group2, material);
            whenBothLoaded();
        });
    }
}

export class DogModel {
    constructor() {
        this.dogGroup = null;
        this.catGroup = null;
    }

    load(callback) {
        const whenBothLoaded = () => {
            if(!this.dogGroup || !this.catGroup) {
                return;
            }
            if(this.dogGroup.children.length !== this.catGroup.children.length) {
                return;
            }
            
            this.dogGroup.children.forEach((dogMesh, i) => {
                const catMesh = this.catGroup.children[i];
                const catPositions = catMesh.geometry.attributes.position;
                const catNormals = catMesh.geometry.attributes.normal;
                dogMesh.geometry.setAttribute( 'position2', new Float32BufferAttribute( catPositions.array, 3 ) );
                dogMesh.geometry.setAttribute( 'normal2', new Float32BufferAttribute( catNormals.array, 3 ) );
            });
            callback(this.dogGroup, material);
        }

        const material = getDefaultMaterial();

        const assignMaterial = (group, material) => {
            group.children.forEach((mesh) => {
                mesh.material = material;
            });
        }

        new OBJLoader().load(dogObjURL, group => {
            group.children.forEach((mesh) => {
                mesh.material = material;
            });
            this.dogGroup = group;
            assignMaterial(this.dogGroup, material);
            whenBothLoaded();
        });

        new OBJLoader().load(catObjURL, group => {
            group.children.forEach((mesh) => {
                mesh.material = material;
            });
            this.catGroup = group;
            assignMaterial(this.catGroup, material);
            whenBothLoaded();
        });
    }
}