import React, { useState } from 'react'
import { getClassExampleUrl } from '../../Model/ModelMetaInfo'
import {
    ListItem, ListItemText, Typography, FormControl, InputLabel, Select,
    MenuItem
} from '@material-ui/core'
import { inceptionNameFromIndex } from '../../ImageNetUtil'
import { tf } from '../../TFJS'

export function TopClassView(props) {

    const { modelManager, modelIndex, width, logitActivations,
        showLogits, setNeedsUpdate } = props;

    const [exampleInd, setExampleInd] = useState(0);

    let logits = logitActivations[modelIndex].data;
    if (!showLogits) {
        logits = tf.tensor1d(logits);
        logits = tf.softmax(logits).dataSync();
    }
    logits = Array.from(logits);
    const indexedLogits = logits.map((value, i) => {
        return [i, value];
    });
    console.log("indexed", indexedLogits)
    const sortedLogits = indexedLogits.sort((a, b) => {
        return b[1] - a[1];
    });
    const sortedInds = sortedLogits.map(([ind, val]) => ind);
    const topClassImageSrc = getClassExampleUrl(sortedInds[exampleInd]);

    let minLog = 1000000, maxLog = -1000000;
    let top5 = sortedLogits.slice(0, 5).map(([ind, val]) => {
        minLog = Math.min(val, minLog);
        maxLog = Math.max(val, maxLog);
        return [inceptionNameFromIndex(ind), val];
    });
    top5 = top5.map(([name, val]) => {
        const normalized = (val) / (maxLog);
        return [name, val, normalized];
    });

    return (<div className="column" style={{ width: width }}>
        <div className="row">
            <img style={{ borderRadius: "20px" }} width={width} height={width} src={topClassImageSrc}></img>
        </div>
        <div className="row" style={{ padding: "5px 5px 5px 10px", background: "#fff", borderRadius: "5px", }}>
            <div className="column" style={{ width: "70%" }}>
                <Typography>
                    Class Name
                    </Typography>
            </div>
            <div className="column" style={{ width: "30%", position: "relative" }}>
                <Typography>
                    {showLogits ? "Logit Val." : "Probability"}
                </Typography>
            </div>
        </div>
        {top5.map(([name, val, normalized], i) => {
            return <div className="row" style={{ padding: "5px 5px 5px 10px", background: i == exampleInd ? "#ddd" : "#fff", borderRadius: "5px", }}
                onMouseEnter={() => {
                    setExampleInd(i);
                }}
                onMouseLeave={() => {
                    setExampleInd(0);
                }}>
                <div className="column" style={{ width: "70%" }}>
                    <Typography>
                        {name}
                    </Typography>
                </div>
                <div className="column" style={{ width: "30%", position: "relative" }}>
                    <div style={{
                        width: (normalized * 100) + "%",
                        borderRadius: "5px",
                        height: "100%",
                        background: "black"
                    }} />
                    <div style={{
                        padding: "4px", color: "white", mixBlendMode: "difference", position: "absolute", top: 0, left: 0,
                        zIndex: 10, fontFamily: "Roboto, Helvetica, Arial, sans-serif", fontSize: "auto"
                    }}>
                        {val.toFixed(2)}
                    </div>
                </div>
            </div>
        })}
    </div>
    );
}