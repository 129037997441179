import React from 'react'
import { Typography } from '@material-ui/core'

const getTypographyRow = (content, fontSize) => {
    let style={};
    if(fontSize){
        style["fontSize"] = fontSize;
    }
    return<div className="row" >
        <Typography variant="body1" style={style}>{content}</Typography>
    </div>
}

export function ClassificationOverlay(props) {
    const {model, overlayImage, clsName, prob} = props;

    let origClsName, currClsName, origProb, currOrigProb, currProb;
    if (overlayImage) {
        const { startPred, currentPred } = overlayImage;
        const { prob, cls, origClsProb } = currentPred;
        const origCls = startPred.cls;
        origClsName = model.getClassName(origCls);
        currClsName = model.getClassName(cls);
        origProb = startPred.prob.toFixed(3);
        currOrigProb = origClsProb.toFixed(3);
        currProb = prob.toFixed(3);
    } else {
        currClsName = clsName;
        currProb = prob;
    }

    const rStyle = {
        position: 'absolute', width: "100%", height: "25%", padding: 5,
        left: 0, bottom: 0, background: "white", opacity: 0.5,
    };

    const getAdvOverlay = (origClsName, origProb, currOrigProb, currClsName, currProb) => {
        return(
        <div className="row"
            style={rStyle}>
            <div style={{ width: "50%", height: "100%" }} className="column">
                {getTypographyRow("Orig. pred.")}
                {getTypographyRow(origClsName+": "+origProb, 12)}
            </div>
            <div style={{ width: "50%", height: "100%" }} className="column">
                {getTypographyRow("Curr. pred.")}
                {getTypographyRow(origClsName+": "+currOrigProb, 12)}
                {getTypographyRow(currClsName+": "+currProb, 12)}
          </div>
        </div>
        );
    }

    const getStdOverlay = (currClsName, currProb) => {
        return(
            <div className="row"
                style={rStyle}>
                <div style={{ width: "100%", height: "100%" }} className="column">
                    {getTypographyRow("Curr. pred.")}
                    {getTypographyRow(currClsName+": "+currProb, 12)}
              </div>
            </div>
            );
    }

    if(overlayImage) {
        return getAdvOverlay(
            origClsName, origProb, currOrigProb, currClsName, currProb);
    } else {
        return getStdOverlay(currClsName, currProb);
    }
}