export const ttInteractiveScene = "Interactive 3d scene that gets continuously fed into CNN models as input. Use mouse to control view, change parameters below for additional effects."
export const ttSceneViewOptions = "Show options for interactive 3d scene"

export const ttCentralView = "Visualization of intermediate-layer activations at the top. Controls for assigning models to \"Model 1\" & \"Model 2\" slots at the bottom."
export const ttCentralViewOptions = "Show options for intermediate layer visualization"
export const ttCentralModelCheckBoxes = "Choose which model's activations to show. Each enabled model has a performance impact."
export const ttFeatureMapView = "Visualization of feature map activations. Positive values are red, negative values are blue."
export const ttFeatureVisView = "Feature visualizations show us image patterns that the respective neurons respond to most strongly."
export const ttLayerSelect = "Select layer to then choose neurons from."
export const ttNeuronSelect = "Select interesting neuron categories to visualize above. Neurons categories are taken from \"Circuits\" project."
export const ttSelectModel1 = "Assign Model 1 slot. Choose either standard ImageNet trained InceptionV1 or a fine-tuned variant. Fine-tuned models include adversarially trained & Stylized ImageNet trained models."
export const ttSelectModel2 = "Assign Model 2 slot. Choose either standard ImageNet trained InceptionV1 or a fine-tuned variant. Fine-tuned models include adversarially trained & Stylized ImageNet trained models."
export const ttSelectIteration = "Select iteration from fine-tuning process. Dragging just changes feature visualizations, mouse release loads model checkpoint. The adversarially fine-tuned model behaves strangely before iteration 15K. Can we find out why?"

export const ttWeightEditing = "Toggle weight editing view. Here you can experiment with mixing together weights from Model 1 and Model 2, and also with pruning away channels by weight magnitude."
export const ttWeightEditingOptions = "Show options for weight editing view"
export const ttEditingMode = "Select between model mixing and model pruning. The sliders below control the selected editing mode."

export const ttTopClassViewOptions = "Show options for Top Class view"
export const ttTopClassModelCheckBoxes = "Choose which model's prediction to show. Each enabled model has a massive performance impact."

export const ttAdversarialAttackParams = "Press PGD STEP button to initiate adversarial attack. Computing the gradient function takes a few secs in the beginning. Click onto scene to reset attack."
export const ttAAModel= "Model to use for generating the attack"
export const ttAANorm = "Switch between L-2 and L-inf norm"
export const ttAATarget = "Choose a target class or suppress original prediction"
export const ttAAAttackAlpha = "Fade attack in and out"
export const ttAAOriginalAlpha = "Fade original image in and out to view isolated attack image"
export const ttAAPGDButton = "Do PGD step, needs around 8 clicks to reach attack magnitude"
export const ttAAMagnitude = "Set attack magnitude"

export const ttSceneParams = "Parameters controlling various aspects of the 3d scene before rasterization"
export const ttSCBGBlur = "Background blur"
export const ttSCBGSaturation = "Background saturation"
export const ttSCTextureInfl = "Blends the foreground object's texture with solid white"
export const ttSCTextureBlur= "Blurs the foreground object's texture"
export const ttSCLightingInfl= "Decrease to blend with uniform lighting"
export const ttSCFeatVis= "Display plane with feature visualization instead of foreground object"
export const ttSCFVToShow= "Select which feature visualization to show, in <layer>:<neuron> format"

export const ttCatnessParams = "Seamlessly transform dog into cat or fire truck into race car. Change shape & texture together or separately."

export const ttPPParams = "Parameters for effects that are applied after rasterization, to the rendered 2d image."
export const ttPPAlpha = "Simple alpha multiplication"
export const ttPPHue = "Shift each pixels hue in HSV space"
export const ttPPSaturation = "Change color saturation"
export const ttPPPatchShuffling = "Divide image into K x K grid and reorder grid cells"
export const ttPPCustomParam = "Parameter from 0 to 1 to be used in cusom GLSL code below"

export const ttFreqDecompParams = "Splits image into two frequency bands. Cutoff determines frequency where to split. Then both frequency components can be blurred separately (low/high sigma)."

export const ttCameraParams = ""