import React from 'react'
import {Fab, IconButton} from '@material-ui/core'
import {ArrowForward, ArrowBack} from '@material-ui/icons' 
import "./ArrowOverlay.css"

export function ArrowOverlay(props) {
    const {onRightClick, onLeftClick} = props;

    return (<div className="row container" style={{position:"absolute",
    top:"40%", left:0, 
    width:"100%", height:"20%", zIndex: 10}}>
        <div style={{flex:"0 1 50px", display:"flex", alignItems:"center"}}>
            <Fab size="medium"
                onClick={onLeftClick}>
                <ArrowBack />
            </Fab>
        </div>
        <div style={{flex:"1 1 100%"}}></div>
        <div style={{flex:"0 1 50px", display:"flex", alignItems:"center"}}>
            <Fab size="medium"
                onClick={onRightClick}>
                <ArrowForward />
            </Fab>
        </div>
    </div>);
}