import {Matrix4} from 'three'

const identity = new Matrix4();

export default {
    uniforms: {
        tDiffuse: { type: 't', value: null},
        saturation: {value: 1.0}
    },
  
    vertexShader: [
        'varying vec2 vUV;',
        'void main(void) {',
        '    gl_Position = projectionMatrix * viewMatrix * modelMatrix * vec4(position, 1.0);',
        '    vUV = uv;',
        '}'
    ].join('\n'),
  
    fragmentShader: [
        'precision highp float;',
        'precision highp sampler2D;',
        'uniform sampler2D tDiffuse;',
        'uniform float saturation;', 
        'varying vec2 vUV;',

        '//https://gist.github.com/sugi-cho/6a01cae436acddd72bdf',
        'vec3 rgb2hsv(vec3 c)',
        '{',
            'vec4 K = vec4(0.0, -1.0 / 3.0, 2.0 / 3.0, -1.0);',
            'vec4 p = mix(vec4(c.bg, K.wz), vec4(c.gb, K.xy), step(c.b, c.g));',
            'vec4 q = mix(vec4(p.xyw, c.r), vec4(c.r, p.yzx), step(p.x, c.r));',

            'float d = q.x - min(q.w, q.y);',
            'float e = 1.0e-10;',
            'return vec3(abs(q.z + (q.w - q.y) / (6.0 * d + e)), d / (q.x + e), q.x);',
        '}',

        'vec3 hsv2rgb(vec3 c)',
        '{',
            'vec4 K = vec4(1.0, 2.0 / 3.0, 1.0 / 3.0, 3.0);',
            'vec3 p = abs(fract(c.xxx + K.xyz) * 6.0 - K.www);',
            'return c.z * mix(K.xxx, clamp(p - K.xxx, 0.0, 1.0), c.y);',
        '}',
        
        'void main(void) {',
        '    vec3 col = texture2D(tDiffuse, vUV).rgb;',
        '    vec3 hsv = rgb2hsv(col);',
        '    hsv.y = hsv.y*saturation + 0.001;',
        '    col = hsv2rgb(hsv);',
            
        '    gl_FragColor = vec4(col, 1.0);',
        '}'
    ].join('\n')
  }