import { AlphaShader, SaturationShader, HueShader, getCustomShader} from '../Shaders'
import {DataTexture, MirroredRepeatWrapping, LinearFilter, RedFormat, FloatType, NearestFilter} from 'three'

export const getEffectPasses = (fragCode) =>  {

    const customShader = getCustomShader(fragCode);

    return [
        {name:"customParam", shader:customShader},
        {name:"hue", shader:HueShader},
        {name:"saturation", shader:SaturationShader},
        {name:"alpha", shader:AlphaShader},
    ];
}

const effectNames = [
    "customParam",
    "saturation",
    "hue",
    "alpha",
    "cutoffSigma",
    "lowSigma",
    "highSigma" 
];
export const setEffectParamsFromRenderParams = (effectParams, renderParams) => {
    effectNames.forEach(effectName => {
        if(effectName in renderParams) {
            effectParams[effectName] = renderParams[effectName];
        }
    });
}

export const getGaussianKernelTexture = (sigma) => {
    let kernelSize = Math.round(6*sigma);
    kernelSize = Math.max(5,(kernelSize % 2 === 0) ? kernelSize + 1 : kernelSize);
    const midLoc = Math.floor(kernelSize / 2);

    const kernelFun = (mu, x) => {
        const val = Math.exp(-0.5*(Math.pow((x-mu)/sigma, 2)))/(sigma*Math.sqrt(2*3.1415));
        return val;
    }

    const outBuf = new Float32Array(kernelSize);
    for(let i=0; i<kernelSize; i++) {
        outBuf[i] = kernelFun(midLoc, i);
    }

    const texture = new DataTexture(outBuf, kernelSize, 1, RedFormat, FloatType);
    texture.minFilter = LinearFilter;
    texture.magFilter = LinearFilter;
    texture.wrapS = MirroredRepeatWrapping;
    texture.wrapT = MirroredRepeatWrapping;
    texture.needsUpdate = true;

    return texture;
}

//https://stackoverflow.com/questions/521295/seeding-the-random-number-generator-in-javascript
function random(seed) {
    var x = Math.sin(seed++) * 10000;
    return x - Math.floor(x);
}

//https://stackoverflow.com/questions/14033177/random-permutations-for-a-set-of-numbers-in-javascript
export const getRandomShuffledPatchTexture = (k) => {
    const nPatches = k*k;

    const sequence = [];
    for(let i=0; i<nPatches; i++) {
        sequence.push(i);
    }

    // then proceed to shuffle the rsort array      
    for(var idx = 0; idx < sequence.length; idx++)
    {
        var swpIdx = idx + Math.floor(random(idx) * (sequence.length - idx));
        // now swap elements at idx and swpIdx
        var tmp = sequence[idx];
        sequence[idx] = sequence[swpIdx];
        sequence[swpIdx] = tmp;
    }
    
    const outBuf = new Float32Array(nPatches);
    for(let i=0; i<nPatches; i++) {
        outBuf[i] = sequence[i];
    }

    const texture = new DataTexture(outBuf, k, k, RedFormat, FloatType);
    texture.minFilter = NearestFilter;
    texture.magFilter = NearestFilter;
    texture.needsUpdate = true;

    return texture;
}