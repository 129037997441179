import {Matrix4} from 'three'

export default {
  uniforms: {
    dataTexture: { type: 't', value: null},
    normalize: {value: 1.0}
  },
  
    vertexShader: [
      'attribute float thirdCoord;',
      'attribute float activation;',
      'varying vec2 vUV;',
      'varying float vW;',
      'varying float vActivation;',

			'void main(void) {',
      '	gl_Position = projectionMatrix * viewMatrix * instanceMatrix * vec4(position, 1.0);',
      '	vUV = uv;',
      '	vW = thirdCoord;',
      '	vActivation = activation;',
			'}'
    ].join('\n'),
  
    fragmentShader: [
      'precision highp float;',
      'precision highp sampler3D;',
      'uniform sampler3D dataTexture;',
      'uniform float normalize;',
			
      'varying vec2 vUV;',
      'varying float vW;',
      'varying float vActivation;',
			
      'void main(void) {',
      ' vec3 col = vec3(texture(dataTexture, vec3(vUV.x, vW, 1.0-vUV.y)).rgb);',
      ' float alpha = clamp(vActivation*normalize, 0.0, 1.0);',
      '	gl_FragColor = vec4(col*alpha, alpha);',
      '}',
    ].join('\n')
  }