import React, { Component } from 'react';
import { IconButton, Fab } from "@material-ui/core";

import PlayIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import ResetIcon from '@material-ui/icons/Replay';
import SkipIcon from '@material-ui/icons/SkipNext';

/**
 * Component that contains play/stop-, reset- and single step buttons
 */
export function PlayButtonGroup (props) {
    const {isPlaying, onPlay, onPause, onReset, onStep} = props;

    return (
        <div className="timeline-controls">
            <IconButton className="ui-resetButton" id="reset-button"
                title="Reset the network"
                onClick={onReset}
                size="small">
                <ResetIcon />
            </IconButton>
            <Fab color="primary" className="ui-playButton"
                id="play-pause-button" title="Run/Pause"
                disabled={false}
                onClick={() => {
                    if (isPlaying) {
                    onPause();
                    } else {
                    onPlay();
                    }
                }}
                >
                {isPlaying ? <PauseIcon /> : <PlayIcon />}
            </Fab>
            <IconButton className="ui-stepButton" id="next-step-button"
                title="Step"
                disabled={isPlaying}
                onClick={onStep}
                size="small"
                >
                <SkipIcon />
            </IconButton>
        </div>
    );
}