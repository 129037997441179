import {Matrix4} from 'three'

const identity = new Matrix4();

export default {
    uniforms: {
        color: { type: 't', value: null},
        postRot: {value: identity},
        preRot: {value: identity},
    },
  
    vertexShader: [
        'uniform float zPos;',
        'varying vec2 vUV;',
        'uniform mat4 postRot;',
        'uniform mat4 preRot;',

        'void main(void) {',
        '    gl_Position = projectionMatrix * postRot * viewMatrix * preRot * modelMatrix * vec4(position, 1.0);',
        '    vUV = uv;',
        '}'
    ].join('\n'),
  
    fragmentShader: [
        'precision highp float;',
        'precision highp sampler2D;',
        'uniform sampler2D color;',
        'varying vec2 vUV;',
        
        'void main(void) {',
        '    vec2 uv = vUV * 100.0 - 50.0;',
        '    vec3 col = vec3(0.5);',
        '    float centerOff = length(uv);',
        '    if(centerOff < 0.5) {',
        '       col = texture(color, uv+0.5).rgb;',
        '    }',
        '    else if(centerOff < 0.65) {',
        '       float interp = (0.65-centerOff)/0.15;',
        '       col = texture(color, uv+0.5).rgb*interp + vec3(0.5)*(1.0-interp);',
        '    }',
        '    gl_FragColor = vec4(col, 1.0);',
        '    gl_FragDepth = -1.0;',
        '}'
    ].join('\n')
  }