import {archId} from './ModelMetaInfo'

function getUint8ToInceptionInputInfo(sceneWidth, sourceModel) {
    const rawInputShape = [1, sceneWidth, sceneWidth, 4];
    const s = sourceModel.input.shape;
    const inputShape = [1, s[1], s[2], s[3]];
    const rawInputRange = [0, 255];
    const rawOutputRange = [-117, 138];
    return {
        rawInputShape: rawInputShape,
        inputShape: inputShape,
        rawInputRange:rawInputRange,
        rawOutputRange: rawOutputRange,
        imageNetWhiten: false
    }
}

function getUint8ToTensorpackResnetInputInfo(sceneWidth, sourceModel) {
    const rawInputShape = [1, sceneWidth, sceneWidth, 4];
    const s = sourceModel.input.shape;
    const inputShape = [1, s[1], s[2], s[3]];
    const rawInputRange = [0, 255];
    const rawOutputRange = [0, 1];
    return {
        rawInputShape: rawInputShape,
        inputShape: inputShape,
        rawInputRange:rawInputRange,
        rawOutputRange: rawOutputRange,
        imageNetWhiten: true
    }
}

export function getInputInfo(sceneWidth, model) {
    switch(model.archType) {
        case archId.RESNET_18:
            return getUint8ToTensorpackResnetInputInfo(sceneWidth, model.sourceModel);
        case archId.INCEPTION_V1:
            return getUint8ToInceptionInputInfo(sceneWidth, model.sourceModel);
        default:
            throw "invalid model archtype!";
    }
}


export function getPoolWidthFromDefParam(defParam, h, w) {
    let pw = defParam;
    pw = pw === -1 ? Math.min(h,w) : pw;
    pw = pw > Math.min(h,w) ? Math.min(h,w) : pw;
    return pw;
}