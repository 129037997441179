import React, {useState} from 'react'
import { getDefaultActivations } from '../ActivationViewUtil'
import {CentralView} from './CentralView'
import {ModelMixer} from '../ModelMixer'
import { TopClassContainerView } from './TopClassContainerView';
import { getTestLogitActivations } from '../LogitComponents/LogitViewUtil'
import {Button, Fab, ListItem, Collapse} from '@material-ui/core'
import {Edit} from '@material-ui/icons'


export function ModelView(props) {
    const {modelManager, setNeedsUpdate, sceneCanvasWidth,
        showLoadingProgress, showHelp} = props;

    const [activations, setActivations] = useState(getDefaultActivations());
    const [logitActivations, setLogitActivations] = useState({
        0: getTestLogitActivations(),
        1: getTestLogitActivations(),
        2: getTestLogitActivations()});
    const [showWeightEditing, setShowWeightEditing] = useState(false);


    return (<div className="column">
        <div className="row">
        {true && <div className="column">
            <CentralView
                modelManager={modelManager}
                setNeedsUpdate={setNeedsUpdate}
                neuronWidth={sceneCanvasWidth / 3}
                activations={activations}
                setActivations={setActivations}
                showLoadingProgress={showLoadingProgress}
                showHelp={showHelp}
            />
        </div>}
        <div>
        {showWeightEditing ? <Collapse in={showWeightEditing} timeout="auto">
            <div className="row" style={{width: 280}}>
                <div style={{width:"40px"}} />
                <ModelMixer width={220}
                    modelManager={modelManager}
                    setNeedsUpdate={setNeedsUpdate}
                    showWeightEditing={showWeightEditing}
                    setShowWeightEditing={setShowWeightEditing}
                    showHelp={showHelp}
                     />
                <div style={{width:"40px"}} />
            </div>
        </Collapse> :
            <ListItem button style={{width: "40px", paddingLeft:10, paddingRight:10}} onClick={() => {
                setShowWeightEditing(!showWeightEditing)
            }}>
                <Edit />
            </ListItem>
            }
        </div>
        <div>
            <TopClassContainerView
                modelManager={modelManager}
                logitActivations={logitActivations}
                setLogitActivations={setLogitActivations}
                setNeedsUpdate={setNeedsUpdate}
                showHelp={showHelp}
                />
        </div>
        </div>
    </div>);
}