import React, { useRef, useEffect, useState, useMemo } from 'react'
import ReactDOM from 'react-dom'
import { Canvas, useFrame, useThree, useLoader } from 'react-three-fiber'
import {TextureLoader} from 'three'
import {ObjectMesh} from './ObjectMesh'
import {Controls} from './Controls'
import {Effects} from './Effects/Effects'
import {TextureBillboard} from './TextureBillboard'
import {DataBillboard} from './DataBillboard'
import {setUniformsFromRenderParams} from './AnimationUtils'
import {setEffectParamsFromRenderParams} from './Effects/EffectsUtil'
import { FeatureVisBillboard } from './FeatureVisBillboard'
import { getNeuronThumbURL} from '../../Model/ModelMetaInfo'

function Scene(props){
  const {sceneWidth, onNewFrame, customObj,
     uniforms, bgUniforms, initIterativeProcess, update, setNeedsUpdate, featureVisPath,
     objIndex, backgroundTexture
    } = props;
  const width=sceneWidth, height=sceneWidth;
  const [frame, setFrame] = useState(0);

  const { gl, scene, camera, size } = useThree()

  if(frame == 0) {
    camera.fov = 35;
    camera.position.set(0, 0, .85);
    camera.updateProjectionMatrix();
  }

  const glc = gl.domElement.getContext("webgl2");
  const pixels = useMemo (() => new Uint8Array(width * height * 4), [width, height]);

  useFrame(({ gl, scene, camera }) => {
    setFrame(frame+1);
    glc.clearColor(0.5,0.5,0.5, 1.0);
    glc.clear(gl.COLOR_BUFFER_BIT);
    gl.render(scene, camera);
  }, 1);

  useFrame(({ gl, scene, camera }) => {
    if(!update.needsUpdate) {
      return;
    }
    glc.readPixels(0, 0, width, height, glc.RGBA, glc.UNSIGNED_BYTE, pixels);
    const pixelCopy = new Uint8Array(pixels);
    if(onNewFrame) {
      setNeedsUpdate(false);
      new Promise((resolve, reject) => {
        onNewFrame(pixelCopy, width, height);
        resolve();
      })
    } else {
      console.log("no onNewFrame callback passed!");
    }
    if(initIterativeProcess) {
      initIterativeProcess(pixelCopy, width, height);
    }
  }, 3);

  return(<group>
  <React.Suspense fallback={<mesh />}>
    <TextureBillboard uniforms={bgUniforms}
    textureUrl={backgroundTexture}/>
  </React.Suspense>
  <React.Suspense fallback={<mesh />}>
    <ObjectMesh customObj={customObj}
    objIndex={objIndex}
    uniforms={uniforms}
    setNeedsUpdate={setNeedsUpdate}/>
  </React.Suspense>
  <React.Suspense fallback={<mesh />}>
    <FeatureVisBillboard uniforms={uniforms} texturePath={featureVisPath}/>
  </React.Suspense>
  {/*<DataBillboard imageData={overlayImage} uniforms={{alpha:0.1, lod:0.0}} />*/  }
  </group>)
}

export function SceneCanvas(props) {
  const {modelManager, sceneWidth, uniforms, bgUniforms, effectParams, overlayImage,
    needsUpdate, setNeedsUpdate, animation} = props;

  if('alpha' in uniforms) {
    effectParams.alpha = uniforms['alpha'];
  }
  if ('saturation' in uniforms) {
    effectParams.saturation = uniforms['saturation'];
  }
  if ('hue' in uniforms) {
    effectParams.hue = uniforms['hue'];
  }
  if ('customParam' in uniforms) {
    effectParams.customParam = uniforms['customParam'];
  }

  let renderParams = null;
  const newUniforms = {...uniforms};
  const newBgUniforms = {...bgUniforms};
  const newEffectParams = {...effectParams};
  if(animation) {
    renderParams = animation.getRenderParams();
    setUniformsFromRenderParams(newUniforms, newBgUniforms, renderParams);
    setEffectParamsFromRenderParams(newEffectParams, renderParams);
  }

  return (
    <div style={{position:'absolute', zIndex:0, overflow:"hidden", borderRadius:"20px"}}>
    <Canvas style={{width: sceneWidth, height: sceneWidth, background: "black" }} gl2={true}>
      <ambientLight intensity={0.5}/>
      <pointLight position={[10, 0.4, -1]} intensity={.7}/>
      <pointLight position={[-10, 0.4, 1]} intensity={.7}/>
      <pointLight position={[0, 0, 20]} intensity={.3}/>
      <Controls modelManager={modelManager} onChange={() => {
        setNeedsUpdate(true);
      }}/>
      <Scene {...props}
      uniforms={newUniforms}
      bgUniforms={newBgUniforms}
      needsUpdate={needsUpdate}
      setNeedsUpdate={setNeedsUpdate}/>
      
      <Effects effectParams={newEffectParams} overlayImageData={overlayImage}
      needsUpdate={needsUpdate}/>
    </Canvas>
    </div>
  )
}
