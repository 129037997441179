import React, { useState } from 'react'
import { TopClassView } from './TopClassView'
import {
    ListItem, ListItemText, FormControlLabel, Checkbox,
    Button, ButtonGroup, Collapse, Tooltip, Zoom
} from '@material-ui/core'
import { Menu } from '@material-ui/icons'
import { getLogitOutputDefGenerator, getLogitInferenceCallback } from '../ActivationViewUtil'
import { ttTopClassViewOptions, ttTopClassModelCheckBoxes } from '../../strings'

export function TopClassContainerView(props) {
    const { modelManager, logitActivations, setLogitActivations, setNeedsUpdate,
        showHelp } = props;

    const [visibleModels, setVisibleModels] = useState({ 0: true, 1: true, 2: false });
    const [showOptions, setShowOptions] = useState(false);
    const [showLogits, setShowLogits] = useState(true);

    const loadState = modelManager.useStore(state => state.loaded);
    console.log("topclasscontainer rendered", loadState)
    console.log("topclass logits", logitActivations)

    const params = { normalize: true };
    Object.entries(visibleModels).forEach(([ind, enabled]) => {
        const m = modelManager.getModel(ind);
        console.log("topclass regisering", ind, enabled)
        if (enabled && m.isLoaded()) {
            const generator = getLogitOutputDefGenerator(
                m.sourceModel, getLogitInferenceCallback(
                    logitActivations, setLogitActivations, params, ind), params.normalize == 0, false);
            m.outputManager.registerOutputDefGenerators("topClassView", [generator]);
        } else {
            m.outputManager.deregisterOutputDefGenerators("topClassView");
        }
    });

    const modelClassNames = ["model1Color", "model2Color", "mixedModelColor"];

    return <div className="column">
        <div className="row" style={{ alignItems: "center" }}>
            <div style={{ flex: "1 1 auto" }}>
                <ListItem>
                    <ListItemText primary="Top Classes" />
                </ListItem>
            </div>
            <div className="row" style={{ flex: "0 1 auto" }}>
                <Tooltip title={showHelp ? ttTopClassViewOptions : ""}>
                    <Button onClick={() => {
                        setShowOptions(!showOptions);
                    }}>
                        <Menu />
                    </Button>
                </Tooltip>
            </div>
        </div>
        <Collapse in={showOptions} timeout="auto">
            <div className="row" style={{ padding: 10 }}>
                <ButtonGroup color="primary">
                    <Button variant={showLogits ? "contained" : ""} onClick={() => {
                        setShowLogits(true);
                    }}>Logits</Button>
                    <Button variant={showLogits ? "" : "contained"} onClick={() => {
                        setShowLogits(false);
                    }}>Probability</Button>
                </ButtonGroup>
            </div>
        </Collapse>
        <Tooltip title={showHelp ? ttTopClassModelCheckBoxes : ""}>
            <div className="row">
                <div className="model1Color padding" style={{ flex: "1 1 33%", borderRadius: "10px" }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={visibleModels[0]}
                                onChange={() => {
                                    const newVisibleModels = { ...visibleModels };
                                    newVisibleModels[0] = !visibleModels[0];
                                    setVisibleModels(newVisibleModels);
                                    setNeedsUpdate(true);
                                }}
                                name="checkedB"
                                color="primary"
                            />
                        }
                        label="Model 1"
                    />
                </div>
                <div className="model2Color padding" style={{ flex: "1 1 33%", borderRadius: "10px" }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={visibleModels[1]}
                                onChange={() => {
                                    const newVisibleModels = { ...visibleModels };
                                    newVisibleModels[1] = !visibleModels[1];
                                    setVisibleModels(newVisibleModels);
                                    setNeedsUpdate(true);
                                }}
                                name="checkedB"
                                color="primary"
                            />
                        }
                        label="Model 2"
                    />
                </div>
                <div className="mixedModelColor padding" style={{ flex: "1 1 33%", borderRadius: "10px" }}>
                    <FormControlLabel
                        style={{ width: "150px" }}
                        control={
                            <Checkbox
                                checked={visibleModels[2]}
                                onChange={() => {
                                    const newVisibleModels = { ...visibleModels };
                                    newVisibleModels[2] = !visibleModels[2];
                                    setVisibleModels(newVisibleModels);
                                    setNeedsUpdate(true);
                                }}
                                color="primary"
                            />
                        }
                        label="Edited Model"
                    />
                </div>
            </div>
        </Tooltip>
        <div className="row">
            {Object.entries(visibleModels).map(([ind, visible]) => {
                return <div>{visible && <div style={{ padding: 10, borderRadius: "10px" }} className={"column " + modelClassNames[ind]}>
                    <TopClassView
                        modelManager={modelManager}
                        modelIndex={ind}
                        width={300}
                        showLogits={showLogits}
                        logitActivations={logitActivations}
                        setNeedsUpdate={setNeedsUpdate}
                    />
                </div>}
                </div>;
            })}
        </div>
    </div>
}