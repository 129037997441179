import {ObjectShader} from './Shaders'
import {OBJLoader} from './OBJLoader'
import {ShaderMaterial, DoubleSide, Texture} from 'three'

export const defaultDiffuseURL = process.env.PUBLIC_URL + '/assets/dog/Textures/Color.jpg';
export const catDiffuseURL = process.env.PUBLIC_URL + '/assets/cat/Textures/Color.jpg';
export const defaultMaskURL = process.env.PUBLIC_URL + '/assets/dog/Textures/FurMaskBW.jpg';
const defaultObjURL = process.env.PUBLIC_URL + "/assets/dog/Shepherd.obj"

export function getDefaultMaterial() {
    const material = new ShaderMaterial({
        uniforms: ObjectShader.uniforms,
        vertexShader: ObjectShader.vertexShader,
        fragmentShader: ObjectShader.fragmentShader,
        side: DoubleSide,
        lights: true,
        alphaTest: 0.2,
        transparent: true
    });
    return material;
}

export function loadDefaultModel(callback) {
    new OBJLoader().load(defaultObjURL, group => {
        const material = getDefaultMaterial();
        group.children.forEach((mesh) => {
            mesh.material = material;
        });
        callback(group, material);
    });
}

export function loadTexture(texture, textureFile) {
    const reader = new FileReader();
    reader.onloadend = function(event) {
        const byteBuffer = event.target.result;
        const imageBlob = new Blob([byteBuffer], {type: "image/jpg"});
        // not supported in IE / Edge / Safari! But it is ok to exclude them from uploading custom textures...
        createImageBitmap(imageBlob, {imageOrientation: 'flipY'}).then(function(imageBitmap) {
            texture.image = imageBitmap;
            texture.needsUpdate = true;
        });
    }

    reader.readAsArrayBuffer(textureFile);
}

export function loadCustomModel(customObj, callback) {
    const reader = new FileReader();
    let diffTexture = null, alphaTexture = null;
    if(customObj.diffTexFile) {
        diffTexture = new Texture()
        loadTexture(diffTexture, customObj.diffTexFile);
    }
    if(customObj.alphaTexFile) {
        alphaTexture = new Texture()
        loadTexture(alphaTexture, customObj.alphaTexFile);
    }
    reader.onloadend = function(event) {
        const textContent = event.target.result;

        const group = new OBJLoader().parse(textContent);
        const material = getDefaultMaterial();
        material.uniforms.colorMap.value = diffTexture;
        material.uniforms.alphaMap.value = alphaTexture;
        group.children.forEach((mesh) => {
            mesh.material = material;
        });
        callback(group, material);
    }


    reader.onerror = function(event) {
        console.error("File could not be read! Code " + event.target.error.code);
    };

    reader.readAsText(customObj.objFile);
}

export function setApplicablePropUniforms (material, uniforms) {
    if(material && uniforms) {
        Object.keys(uniforms).forEach((key) => {
            if(key in material.uniforms) {
                material.uniforms[key].value = uniforms[key];
            }
        });
    }
}