import {resizeRGBAImg} from '../Util/RenderingUtil'


export class TuningCurvePlot {
    constructor(resolution, isOneD=false, imageWidth=64) {
        this.resolution = resolution;
        this.imageWidth = imageWidth;

        this.data = new Float32Array(
            resolution*(isOneD?1:resolution)*imageWidth*imageWidth*4);
    }

    clearCanvas(){
    }

    getDataOffset(frameNumber) {
        return frameNumber*this.imageWidth*this.imageWidth*4;
    }

    putImage(frameNumber, imageData) {
        resizeRGBAImg(imageData, this.imageWidth, this.imageWidth, (result) => {
            const dataOffset = this.getDataOffset(frameNumber);
            console.log("dataOffset", dataOffset)
            this.data.set(result, dataOffset);
        })
    }
}